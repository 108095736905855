/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import { getImageUrl } from 'utils/getImageUrl';
import arrowIcon from 'images/arrowGames.svg';
import { PageType } from 'src/enums/PageType';
import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { IGameInnerWithList, IPageTypes } from 'interfaces';
import { Link } from 'components/Link';

import classes from './index.module.scss';

interface IGameInnerProps {
    data: IGameInnerWithList;
    pageTypes: IPageTypes;
}

export const NextPreviousGames: FC<IGameInnerProps> = (props) => {
    const { id } = props.data.currentGame;
    const { edges: games } = props.data.allGames;

    const currentGameIndex = games.findIndex((element: { node: { id: number } }) => element.node.id === id);
    const currentGameOrder = games[currentGameIndex].node.order;
    const previousGameOrder = currentGameOrder - 1 > 0 ? currentGameOrder - 1 : games.length;
    const nextGameOrder = currentGameOrder + 1 <= games.length ? currentGameOrder + 1 : 1;
    const previousGame = games.find((element: { node: { order: number } }) => element.node.order === previousGameOrder);
    const nextGame = games.find((element: { node: { order: number } }) => element.node.order === nextGameOrder);

    return (
        <div className={classes.otherPagesContainer}>
            <div className={classes.otherPages}>
                <div className={classes.prevGameContainer}>
                    <Link
                        url={`/${previousGame?.node.locale}/${getPageSlugByType(PageType.products, props.pageTypes)}/${
                            previousGame?.node.slug
                        }`}
                        className={classes.link}
                    >
                        <img src={arrowIcon} alt="previous" className={classes.prevArrow} />
                        <div className={classes.prevGameTitleContainer}>
                            <div className={classes.prevGameType}>{previousGame?.node.gameType?.type}</div>
                            <div className={classes.prevGameTitle}>{previousGame?.node.name}</div>
                        </div>
                        <div className={classes.imageContainer}>
                            <div
                                className={classes.image}
                                style={{
                                    backgroundImage: `linear-gradient(180deg, rgba(10, 26, 51, 0.3) 0%, #0A1A33 100%), url("${getImageUrl(
                                        previousGame?.node.image?.url || '',
                                    )}")`,
                                }}
                            />
                        </div>
                    </Link>
                </div>

                <div className={classes.nextGameContainer}>
                    <Link
                        url={`/${nextGame?.node.locale}/${getPageSlugByType(PageType.products, props.pageTypes)}/${
                            nextGame?.node.slug
                        }`}
                        className={classes.link}
                    >
                        <img src={arrowIcon} alt="next" className={classes.nextArrow} />
                        <div className={classes.nextGameTitleContainer}>
                            <div className={classes.nextGameType}>{nextGame?.node.gameType?.type}</div>
                            <div className={classes.nextGameTitle}>{nextGame?.node.name}</div>
                        </div>
                        <div className={classes.imageContainer}>
                            <div
                                className={classes.image}
                                style={{
                                    backgroundImage: `linear-gradient(180deg, rgba(10, 26, 51, 0.3) 0%, #0A1A33 100%), url("${getImageUrl(
                                        nextGame?.node.image?.url || '',
                                    )}")`,
                                }}
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};
