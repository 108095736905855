import { INodes } from 'src/interfaces/IPost';

export const sortPostsByDate = (posts: INodes[]) => {
    return posts.sort((a, b) => {
        return (
            new Date(b.node.date || b.node.published_at).getTime() -
            new Date(a.node.date || a.node.published_at).getTime()
        );
    });
};
