import React from 'react';
import classNames from 'classnames';
import { CheckIcon, CloseIcon } from 'components/Icon';
import { IMessage } from 'components/Contacts/interfaces';
import classes from './Message.module.scss';

interface IProps {
    message: IMessage;
}

export const Message: React.FC<IProps> = (props) => {
    const isSuccessType = !!props.message.success;

    return (
        <div
            className={classNames(classes.container, {
                [classes.success]: isSuccessType,
                [classes.error]: !isSuccessType,
            })}
        >
            <Choose>
                <When condition={isSuccessType}>
                    <CheckIcon className={classes.iconSuccess} />
                    <div>{props.message.success}</div>
                </When>
                <Otherwise>
                    <CloseIcon className={classes.iconError} />
                    <div>{props.message.error}</div>
                </Otherwise>
            </Choose>
        </div>
    );
};
