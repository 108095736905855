import React, { FC, useMemo } from 'react';

import { ITextList } from 'src/interfaces';
import { Text } from 'components/Text';
import { Base } from './Base';

import classes from './index.module.scss';

export const BulletList: FC<ITextList> = ({ itemsTextColor, columns, backgroundColor, header }) => {
    const textColor = useMemo(() => itemsTextColor && JSON.parse(itemsTextColor), [itemsTextColor]);
    const bgColor = useMemo(() => backgroundColor && JSON.parse(backgroundColor), [backgroundColor]);

    return (
        <Base {...{ header }}>
            <div className={classes.List__bulletAndIcons} style={{ backgroundColor: bgColor?.hex }}>
                {columns.map(({ id, items }) => (
                    <div key={id}>
                        {items.map(({ id: _id, icon, title }) => (
                            <Text key={_id} icon={icon} title={title} color={textColor} />
                        ))}
                    </div>
                ))}
            </div>
        </Base>
    );
};
