import React, { FC, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { navigate } from 'gatsby';

import { IApplicationForm } from 'interfaces';
import { Loader } from 'components/Loader';
import { Message } from 'components/Contacts/Message';
import { useMessage } from 'components/Contacts/hooks/useMessage';
import { Input } from 'src/components/inputs';
import { PHONE_REGEX } from 'constants/regexp';
import { LayoutContext } from 'src/templates/Layout/Layout';
import { FormBase } from './Base';

import classes from './index.module.scss';

interface IOnSubmit {
    coverLetter: string;
    email: string;
    lastName: string;
    linkedin: string;
    name: string;
    phone: string;
}

const FIELD_NAMES: { [x: string]: keyof IApplicationForm } = {
    name: 'name',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    linkedin: 'linkedin',
    coverLetter: 'coverLetter',
};

export const ApplicationForm: FC<IApplicationForm> = ({
    header,
    description,
    submitText,
    emailTo,
    messageError,
    backgroundImage,
    locale,
    position,
    ...fields
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const context = useContext(LayoutContext);

    const { message: responseMessage, updateMessage, resetMessage } = useMessage();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            [FIELD_NAMES.name]: '',
            [FIELD_NAMES.lastName]: '',
            [FIELD_NAMES.email]: '',
            [FIELD_NAMES.phone]: '',
            [FIELD_NAMES.linkedin]: '',
            [FIELD_NAMES.coverLetter]: '',
        },
        resolver: yupResolver(
            yup.object({
                [FIELD_NAMES.name]: fields.name.errorIsRequired
                    ? yup.string().required(fields.name.errorIsRequired)
                    : yup.string(),
                [FIELD_NAMES.lastName]: fields.lastName.errorIsRequired
                    ? yup.string().required(fields.lastName.errorIsRequired)
                    : yup.string(),

                [FIELD_NAMES.linkedin]: fields.linkedin.errorIsRequired
                    ? yup.string().required(fields.linkedin.errorIsRequired)
                    : yup.string(),
                [FIELD_NAMES.coverLetter]: fields.coverLetter.errorIsRequired
                    ? yup.string().required(fields.coverLetter.errorIsRequired)
                    : yup.string(),
                [FIELD_NAMES.email]: fields.email.errorIsRequired
                    ? yup
                          .string()
                          .required(fields.email.errorIsRequired)
                          .email(fields.email.errorIsNotValid || 'Email is not valid')
                    : yup.string().email(fields.email.errorIsNotValid || 'Email is not valid'),
                [FIELD_NAMES.phone]: fields.phone.errorIsRequired
                    ? yup
                          .string()
                          .matches(PHONE_REGEX, fields.phone.errorIsNotValid || 'Phone is not valid')
                          .required(fields.phone.errorIsRequired)
                    : yup.string().matches(PHONE_REGEX, {
                          message: fields.phone.errorIsNotValid || 'Phone is not valid',
                          excludeEmptyString: true,
                      }),
            }),
        ),
    });
    const isError = !!Object.keys(errors).length;
    const watchAllFields = watch();
    const formRequiredValues = {
        ...(!!fields.coverLetter.errorIsRequired && { [FIELD_NAMES.coverLetter]: getValues('coverLetter') }),
        [FIELD_NAMES.email]: getValues('email'),
        ...(!!fields.lastName.errorIsRequired && { [FIELD_NAMES.lastName]: getValues('lastName') }),
        ...(!!fields.linkedin.errorIsRequired && { [FIELD_NAMES.linkedin]: getValues('linkedin') }),
        ...(!!fields.name.errorIsRequired && { [FIELD_NAMES.name]: getValues('name') }),
        ...(!!fields.phone.errorIsRequired && { [FIELD_NAMES.phone]: getValues('phone') }),
    };

    const onSubmit = async ({ coverLetter, email, lastName, linkedin, name, phone }: IOnSubmit): Promise<void> => {
        resetMessage();
        setIsFetching(true);

        axios
            .post(`${STRAPI_API_URL}/application-emails`, {
                email,
                name,
                lastName,
                linkedin,
                coverLetter,
                phone,
                emailTo: emailTo.id,
                position,
            })
            .then(({ data: _data }) => {
                if (_data.error) {
                    updateMessage({ error: messageError });
                } else {
                    navigate(`/${locale}/${context.thankYouPageSlug}`, { replace: true });
                }
            })
            .catch((e) => updateMessage({ error: e.toString() }))
            .finally(() => setIsFetching(false));
    };

    const formInvalid = useMemo(
        (): boolean =>
            Object.values(formRequiredValues)
                .map((value) => !!value)
                .includes(false),
        [watchAllFields],
    );

    return (
        <FormBase
            className={classes.ApplicationForm}
            header={header}
            description={description}
            centerText
            backgroundImage={backgroundImage}
        >
            <form onSubmit={handleSubmit(onSubmit)} className={classes.ApplicationForm__form}>
                <div className={classes.ApplicationForm__form__fields}>
                    {Object.keys(FIELD_NAMES).map((name, idx) => (
                        <Input
                            key={idx}
                            register={register}
                            type={fields[name].type}
                            placeholder={fields[name].placeholder}
                            title={fields[name].title}
                            name={name}
                            error={errors[name]?.message}
                            resetField={resetField}
                        />
                    ))}
                    <div className={classes.ApplicationForm__form__fields__fullWidth}>
                        {isFetching && <Loader className={classes.spinner} />}
                        {!!responseMessage.error && <Message message={responseMessage} />}
                        <button
                            className={classes.ApplicationForm__form__fields__button}
                            disabled={isError || formInvalid || isFetching}
                            type="submit"
                        >
                            {submitText || 'Send'}
                        </button>
                    </div>
                </div>
            </form>
        </FormBase>
    );
};
