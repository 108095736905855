import React, { useMemo } from 'react';
import { IGatsbyConnection, IGatsbyEdge } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { IHeading, IPartner } from 'interfaces';
import classNames from 'classnames';
import { Heading } from 'components/Heading';
import { MarqueeAnimation } from 'components/MarqueeAnimation';
import { Partner } from './Partner';
import classes from './PartnersList.module.scss';

interface IProps {
    heading: IHeading;
    partners: IGatsbyConnection<IPartner>;
    awards: IGatsbyConnection<IPartner>;
    partner_category: {
        id: number;
    };
}

export const PartnersList: React.FC<IProps> = ({ partners, heading, partner_category, awards }) => {
    const filterPartnersByCategory = (partnerList: IGatsbyConnection<IPartner>): IGatsbyEdge<IPartner>[] =>
        partnerList.edges.filter(
            ({ node }) => node.partner_category?.id?.toString() === partner_category?.id?.toString(),
        );

    const filteredPartners = useMemo(
        () => (partner_category && partners ? filterPartnersByCategory(partners) : null),
        [partner_category, partners],
    );

    return (
        <div className={classNames(heading.removeTopSpacing ? classes.Awards__noMargins : classes.Awards__container)}>
            <Heading heading={heading} className={classNames({ [classes.heading]: !heading.removeTopSpacing })} />
            <MarqueeAnimation<IGatsbyEdge<IPartner>>
                list={filteredPartners || partners?.edges || awards.edges}
                itemKey="node.id"
                itemTitle="node.name"
                renderItem={({ node }) => <Partner url={node.url} logo={node.logo} />}
            />
        </div>
    );
};
