import React, { FC } from 'react';
import { IEmployeeCard } from 'interfaces';
import classNames from 'classnames';
import { getImageUrl } from 'utils/getImageUrl';
import { LinkIcon } from 'components/Icon';
import { Link } from 'components/Link';
import { useLocation } from '@reach/router';
import { LOCALE_REGEX, URL_REGEX } from 'src/constants/regexp';
import classes from './Card.module.scss';

export const EmployeeCard: FC<IEmployeeCard> = ({ icon, label, name, email, url, newTab }) => {
    const location = useLocation();
    const locale = LOCALE_REGEX.exec(location.pathname)?.[0];
    const link = url && URL_REGEX.test(url) ? url : `${locale}/${url}`;

    return (
        <>
            <div className={classNames(classes.container, classes.employee__container)}>
                <div>
                    <Link url={url ? link : `mailto:${email}`} newTab={newTab}>
                        <p className={classNames(classes.employee__label)}>{label}</p>
                        <h3 className={classNames(classes.name)}>{name}</h3>
                    </Link>
                    <address className={classNames(classes.email)}>
                        <a href={`mailto:${email}`}>{email}</a>
                    </address>
                </div>

                <div className={classes.container_overlay}>
                    <Link url={link} newTab={newTab}>
                        <div
                            className={classNames(classes.icon_wrapper, classes.employee__icon_wrapper, {
                                [classes.employee__pointer]: url,
                            })}
                        >
                            <img
                                className={classNames(classes.icon, classes.employee__icon)}
                                src={getImageUrl(icon.url)}
                                alt={icon.name}
                            />
                            {url && (
                                <div className={classes.employee__icon__link}>
                                    <LinkIcon />
                                </div>
                            )}
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};
