import L, { Icon } from 'leaflet';
import icon from './marker.svg';

export const MarkerIcon = (): Icon =>
    new L.Icon({
        iconUrl: icon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30],
    });
