import React from 'react';
import { IMessage } from 'components/Contacts/interfaces';

export interface IUseMessage {
    message: IMessage;
    updateMessage(message: IMessage): void;
    resetMessage(): void;
}

const holdMessageTime = 5000;

export const useMessage = (): IUseMessage => {
    const [message, setMessage] = React.useState<IMessage>({});
    const resetMessageTimeout = React.useRef<number>();

    React.useEffect(
        () => () => {
            window.clearTimeout(resetMessageTimeout.current);
        },
        [],
    );

    const resetMessage = (): void => {
        setMessage({});
    };

    const updateMessage = (value: IMessage): void => {
        setMessage(value);

        resetMessageTimeout.current = window.setTimeout(() => {
            resetMessage();
        }, holdMessageTime);
    };

    return { message, updateMessage, resetMessage };
};
