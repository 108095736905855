import React, { FC } from 'react';

import { Image } from 'components/Image';
import { IOurDna } from 'interfaces';

import classes from './index.module.scss';

export const OurDna: FC<IOurDna> = ({ header, image, sections }) => (
    <div className={classes.OurDna}>
        <div>
            <h2>{header}</h2>
            {sections.map(({ id, header: _header, paragraph }) => (
                <div key={id}>
                    <h4>{_header}</h4>
                    <p>{paragraph}</p>
                </div>
            ))}
        </div>
        <Image className={classes.OurDna__image} image={image} />
    </div>
);
