import React, { FC } from 'react';
import classNames from 'classnames';

import { IInputDefault } from 'interfaces';

import classes from './index.module.scss';

export const TextArea: FC<IInputDefault> = ({ className, placeholder, register, name, error }) => (
    <textarea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name)}
        className={classNames(classes.Input__field, className, classes.Input__field__textarea, {
            [classes.invalid]: !!error,
        })}
        placeholder={placeholder}
    />
);
