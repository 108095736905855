import React from 'react';
import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { Banner } from 'components/Banner';
import { IContactComponent, IEmail } from 'interfaces';
import { Heading } from 'components/Heading';
import { Form } from './Form';
import { Map } from './Map';
import classes from './Contacts.module.scss';

interface IProps extends IContactComponent {
    emails: IGatsbyConnection<IEmail>;
    recaptchaV2Key: string;
}

export const Contacts: React.FC<IProps> = (props) => (
    <div className={classes.container}>
        <div className={classes.content}>
            <div className={classes.formWrapper}>
                <If condition={!!props.heading}>
                    <Heading heading={props.heading} className={classes.heading} />
                </If>
                <If condition={!!props.form}>
                    <Form
                        data={props.form}
                        emails={props.emails}
                        locale={props.locale}
                        recaptchaV2Key={props.recaptchaV2Key}
                    />
                </If>
            </div>
            <div className={classes.mapWrapper}>
                <Map address1={props.address1} address2={props.address2} address3={props.address3} />
            </div>
        </div>
        <If condition={!!props.banner}>
            <Banner data={props.banner} />
        </If>
    </div>
);
