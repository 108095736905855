/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { graphql } from 'gatsby';
import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Seo } from 'components/Seo';
import { CookiesConsent } from 'components/CookiesConsent';
import { PageTitle } from 'components/PageTitle';
import { contentSectionMap } from 'components/contentSectionMap';
import {
    IGame,
    IGlobal,
    ILocale,
    IPage,
    IPartner,
    ICurrentPost,
    INodes,
    IPost,
    IGameInner,
    IPositionInner,
    ILocalization,
    IPageWithLocalizations,
    IPublicLocales,
    IPageTypes,
    IParent,
} from 'interfaces';
import { PostInner } from 'components/PostInner';
import { GameInner } from 'components/GameInner';
import { PositionsInner } from 'components/PositionsInner';
import { AgeCheck } from 'src/components/AgeCheck';

import 'src/styles/main.scss';
import classes from './Layout.module.scss';

export const query = graphql`
    query Layout($locale: String!, $id: String!, $strapiId: Int) {
        global: strapiGlobal(locale: { eq: $locale }) {
            siteName
            locale
            logo {
                url
                width
                height
                mime
            }
            footerContent {
                footerNavigation {
                    id
                    url
                    text
                    newTab
                    type
                    column
                }
                footerBottom {
                    id
                    url
                    text
                    newTab
                    type
                }
                footerRichText
                socialTitle
                socialLinks {
                    id
                    type
                    url
                }
                licensesLinks {
                    id
                    url
                    text
                    icon {
                        url
                        width
                        height
                        mime
                    }
                }
                copyright
            }
            defaultSeo {
                metaTitle
                metaDescription
                metaImage
            }
            favicon {
                width
                ext
                hash
                height
                mime
                name
                size
                provider
                url
            }
            navigationLinks {
                id
                url
                newTab
                text
                type
                mainLink
            }
            cookiesConsent {
                consentMessage
                acceptButtonText
                declineButtonText
            }
            recaptchaV2Key
            latestNewsHeader
            thankYouPageSlug
            ageCheckModal {
                primaryText
                secondaryText
                acceptButtonText
                declineButtonText
            }
        }
        pages: allStrapiPage(
            filter: { displayInNavigation: { eq: true }, locale: { eq: $locale } }
            sort: { fields: [sort], order: ASC }
        ) {
            edges {
                node {
                    sort
                    slug
                    title
                    locale
                }
            }
        }
        parents: allStrapiParent(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    sort
                    name
                    pages {
                        slug
                        title
                        locale
                    }
                }
            }
        }
        page: strapiPage(id: { eq: $id }) {
            slug
            title
            locale
            contentSections
            displayInNavigation
            headerImage
            pageSeo {
                metaTitle
                metaDescription
                metaImage
            }
        }
        games: allStrapiGame(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    gameId
                    type
                    locale
                    order
                    image {
                        url
                    }
                    slug
                    gameType {
                        id
                        type
                    }
                }
            }
        }
        posts: allStrapiPost(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    image {
                        url
                    }
                    published_at
                    title
                    section {
                        richText {
                            content
                        }
                    }
                    locale
                    type
                    slug
                    date
                    banner
                }
            }
        }
        latestPosts: allStrapiPost(sort: { fields: published_at, order: ASC }, filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    image {
                        url
                    }
                    published_at
                    title
                    section {
                        richText {
                            content
                        }
                    }
                    locale
                    type
                    slug
                    date
                }
            }
        }

        currentPost: strapiPost(id: { eq: $id }) {
            title
            id
            locale
            image {
                url
            }
            created_at
            type
            authorCard {
                email
                id
                label
                name
                title
                icon {
                    id
                    url
                }
                url
                newTab
            }
            caption
            section {
                heading
                richText {
                    content
                }
            }
            headerImage
            slug
            date
            banner
            postSeo {
                metaTitle
                metaDescription
                metaImage
            }
        }
        currentGame: strapiGame(id: { eq: $id }) {
            id
            name
            block
            banner
            benefits
            videoBlock
            whereToPlay
            headerImage
            slug
            gameSeo {
                metaTitle
                metaDescription
                metaImage
            }
        }
        currentPosition: strapiPosition(id: { eq: $id }) {
            id
            locale
            title
            location
            positionsIntro
            applicationForm
            headerImage
            positionSeo {
                metaTitle
                metaDescription
                metaImage
            }
        }
        newsSlug: strapiPage(pageType: { eq: "news" }, locale: { eq: $locale }) {
            slug
        }
        partners: allStrapiPartner {
            edges {
                node {
                    id
                    name
                    logo {
                        ext
                        height
                        id
                        mime
                        name
                        size
                        url
                        width
                    }
                    partner_category {
                        id
                    }
                    url
                }
            }
        }
        awards: allStrapiAwards {
            edges {
                node {
                    id
                    name
                    logo {
                        url
                    }
                }
            }
        }
        positions: allStrapiPosition(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    position_category {
                        name
                    }
                    title
                    location
                    locale
                    slug
                }
            }
        }
        locales: allStrapiLocale {
            edges {
                node {
                    name
                    code
                    isDefault
                }
            }
        }
        emails: allStrapiEmail(filter: { locale: { eq: $locale } }, sort: { fields: [strapiId], order: ASC }) {
            edges {
                node {
                    title
                    id: strapiId
                }
            }
        }
        pageLocalization: strapiPage(id: { eq: $id }) {
            localizations {
                id
                locale
            }
            locale
            slug
        }
        pagesWithLocalization: allStrapiPage(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        postLocalization: strapiPost(strapiId: { eq: $strapiId }) {
            localizations {
                id
                locale
            }
            locale
            slug
        }
        postsWithLocalization: allStrapiPost(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        gameLocalization: strapiGame(strapiId: { eq: $strapiId }) {
            localizations {
                id
                locale
            }
            locale
            slug
        }
        gamesWithLocalization: allStrapiGame(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        positionLocalization: strapiPosition(strapiId: { eq: $strapiId }) {
            localizations {
                id
                locale
            }
            locale
            slug
        }
        positionsWithLocalization: allStrapiPosition(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        publicLocales: allStrapiGlobal {
            nodes {
                publishLocale
                locale
            }
        }
        pageTypes: allStrapiPage(filter: { locale: { eq: $locale } }) {
            nodes {
                id
                pageType
                slug
            }
        }

        parentPageLocalizations: allStrapiPage {
            nodes {
                strapiId
                slug
                localizations {
                    id
                    locale
                }
                locale
            }
        }
    }
`;

interface IProps {
    data: {
        global: IGlobal;
        pages: IGatsbyConnection<IPage>;
        page: IPage;
        games: IGatsbyConnection<IGame>;
        posts: IGatsbyConnection<IPost>;
        partners: IGatsbyConnection<IPartner>;
        parents: IGatsbyConnection<IParent>;
        locales: IGatsbyConnection<ILocale>;
        currentPost: ICurrentPost;
        latestPosts: { edges: INodes[] };
        newsSlug: { slug: string };
        awards: IGatsbyConnection<IPartner>;
        currentGame: IGameInner;
        currentPosition: IPositionInner;
        pageLocalization: IPageWithLocalizations | null;
        pagesWithLocalization: IGatsbyConnection<ILocalization> | null;
        postLocalization: IPageWithLocalizations | null;
        postsWithLocalization: IGatsbyConnection<ILocalization> | null;
        gameLocalization: IPageWithLocalizations | null;
        gamesWithLocalization: IGatsbyConnection<ILocalization> | null;
        positionLocalization: IPageWithLocalizations | null;
        positionsWithLocalization: IGatsbyConnection<ILocalization> | null;
        publicLocales: IGatsbyConnection<IPublicLocales>;
        pageTypes: IPageTypes;
        parentPageLocalizations: IGatsbyConnection<ILocalization> | null;
    };
    pageContext: {
        locale: string;
    };
}
const defaultContext = {
    thankYouPageSlug: '',
};
export const LayoutContext = React.createContext(defaultContext);

const Layout: React.FC<IProps> = (props) => {
    const pageLocale = props.data.locales.edges.find((edge) => edge.node.code === props.pageContext.locale)?.node;
    const title = props.data.pages.edges.find((edge) => edge.node.slug === 'news')?.node.title;
    let localization = null;
    let collectionWithLocalization = null;
    let seo = null;

    switch (true) {
        case !!props.data.page:
            seo = props.data.page.pageSeo;
            localization = props.data.pageLocalization;
            collectionWithLocalization = props.data.pagesWithLocalization;
            break;
        case !!props.data.currentPost:
            seo = props.data.currentPost.postSeo;
            localization = props.data.postLocalization;
            collectionWithLocalization = props.data.postsWithLocalization;
            break;
        case !!props.data.currentGame:
            seo = props.data.currentGame.gameSeo;
            localization = props.data.gameLocalization;
            collectionWithLocalization = props.data.gamesWithLocalization;
            break;
        case !!props.data.currentPosition:
            seo = props.data.currentPosition.positionSeo;
            localization = props.data.positionLocalization;
            collectionWithLocalization = props.data.positionsWithLocalization;
            break;
        default:
            localization = null;
            collectionWithLocalization = null;
    }

    if (!pageLocale) {
        return null;
    }
    const propsGamesInner = {
        currentGame: props.data.currentGame,
        allGames: props.data.games,
    };

    return (
        <LayoutContext.Provider value={{ ...defaultContext, thankYouPageSlug: props.data.global.thankYouPageSlug }}>
            <div className={classes.container}>
                <Seo global={props.data.global} seo={seo} />
                <Header
                    global={props.data.global}
                    pages={props.data.pages}
                    parents={props.data.parents}
                    locale={pageLocale}
                    locales={props.data.locales}
                    localization={localization}
                    pagesWithLocalization={collectionWithLocalization}
                    publicLocales={props.data.publicLocales}
                    parentPageLocalizations={props.data.parentPageLocalizations}
                />
                <main className={classes.content}>
                    <If condition={!!props.data.page?.title}>
                        <PageTitle image={props.data.page.headerImage}>{props.data.page.title}</PageTitle>
                    </If>
                    {props.data.page &&
                        props.data.page.contentSections.map(({ strapi_component, ...componentProps }, index) => {
                            const componentData = contentSectionMap[strapi_component];
                            if (!componentData) {
                                return null;
                            }

                            const Component = componentData.component;

                            const additionalProps = componentData.props
                                ? { [componentData.props]: props.data[componentData.props as keyof IProps['data']] }
                                : null;

                            return (
                                <Component
                                    locale={props.data.page.locale}
                                    key={index}
                                    {...componentProps}
                                    {...additionalProps}
                                    type={componentData.type}
                                    recaptchaV2Key={props.data.global.recaptchaV2Key}
                                    pageTypes={props.data.pageTypes}
                                />
                            );
                        })}
                    {props.data.currentPost && (
                        <PostInner
                            pageTitle={title}
                            image={props.data.currentPost.image}
                            title={props.data.currentPost.title}
                            label={props.data.currentPost.type}
                            content={props.data.currentPost.content}
                            authorCard={props.data.currentPost.authorCard}
                            caption={props.data.currentPost.caption}
                            sections={props.data.currentPost.section}
                            latestPosts={props.data.latestPosts}
                            latestNewsHeader={props.data.global.latestNewsHeader}
                            newsLink={`/${pageLocale.code}/${props.data.newsSlug?.slug}`}
                            headerImage={props.data.currentPost.headerImage}
                            date={props.data.currentPost.date}
                            banner={props.data.currentPost.banner}
                            pageTypes={props.data.pageTypes}
                        />
                    )}

                    {props.data.currentGame && <GameInner data={propsGamesInner} pageTypes={props.data.pageTypes} />}
                    {props.data.currentPosition && <PositionsInner data={props.data.currentPosition} />}
                </main>
                <Footer global={props.data.global} pages={props.data.pages} locale={pageLocale} />
                <CookiesConsent data={props.data.global.cookiesConsent} />
                <AgeCheck data={props.data.global.ageCheckModal} />
            </div>
        </LayoutContext.Provider>
    );
};

export default Layout;
