import React from 'react';
import ReactPlayer from 'react-player';

import classes from './HeroVideo.module.scss';

interface IProps {
    videoUrl: string;
}

export const HeroVideo: React.FC<IProps> = ({ videoUrl }) => (
    <ReactPlayer width="100%" height="100%" muted playing loop url={videoUrl} className={classes.HeroVideo} />
);
