import React, { FC } from 'react';

import classNames from 'classnames';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';

import classes from './Card.module.scss';

export interface ICard {
    heading: string;
    content: string;
    id: number;
}

export const Card: FC<ICard> = ({ heading, content }) => (
    <div className={classes.card}>
        <h3 className={classNames(classes.card__heading)}>{heading}</h3>
        <HtmlMarkdownContent withSpacing={false} content={content} />
    </div>
);
