import React, { FC, useRef } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './CustomSlickCarousel.scss';
import { getImageUrl } from 'utils/getImageUrl';
import { IHighlightsSection } from 'interfaces';
import arrowWhiteIcon from 'images/arrowWhite.svg';
import arrowGrayIcon from 'images/arrowGray.svg';
import { Card } from './Card';

import classes from './HighlightsSection.module.scss';
import { BREAKPOINTS } from '../../constants/breakpoints';

const MINIMUM_NUMBER_OF_CARDS = 3;
const CAROUSEL_RESPONSIVE_VALUES = [
    {
        breakpoint: BREAKPOINTS.X_LARGE,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: BREAKPOINTS.MEDIUM,
        settings: {
            slidesToShow: 1,
        },
    },
];

export const HighlightsSection: FC<IHighlightsSection> = ({
    card,
    backgroundImage,
    headingColor,
    heading,
    moreInfoText,
    locale,
}) => {
    const carouselRef = useRef<Slider | null>(null);
    const shouldHideNavigationArrows = card.length === MINIMUM_NUMBER_OF_CARDS;

    const next = (): void => {
        carouselRef.current?.slickNext();
    };
    const previous = (): void => {
        carouselRef.current?.slickPrev();
    };
    return (
        <div
            style={{ backgroundImage: `url("${getImageUrl(backgroundImage?.url)}")` }}
            className={classNames(classes.highlights, 'highlights')}
        >
            <h2
                className={classNames(
                    classes.highlights__heading,
                    classes[`highlights__heading__${headingColor}` as string],
                )}
            >
                {heading}
            </h2>
            <div className={classNames(classes.highlights__carousel)}>
                <Slider
                    ref={carouselRef}
                    infinite
                    slidesToShow={3}
                    slidesToScroll={1}
                    arrows={false}
                    swipeToSlide
                    centerMode
                    centerPadding="0"
                    responsive={CAROUSEL_RESPONSIVE_VALUES}
                >
                    {card.map(({ content, heading: cardHeading, url, id, image }) => (
                        <Card
                            key={id}
                            content={content}
                            heading={cardHeading}
                            url={url}
                            locale={locale}
                            id={id}
                            image={image}
                            moreInfoText={moreInfoText}
                        />
                    ))}
                </Slider>
                <div
                    className={classNames(classes.highlights__carousel__buttons, {
                        [classes.highlights__carousel__buttons__hidden]: shouldHideNavigationArrows,
                    })}
                >
                    <button
                        className={classNames(classes.highlights__carousel__buttons__previous)}
                        type="button"
                        onClick={previous}
                    >
                        <img src={backgroundImage ? arrowWhiteIcon : arrowGrayIcon} alt="icon previous" />
                    </button>
                    <button
                        type="button"
                        onClick={next}
                        className={classNames(classes.highlights__carousel__buttons__next)}
                    >
                        <img src={backgroundImage ? arrowWhiteIcon : arrowGrayIcon} alt="icon next" />
                    </button>
                </div>
            </div>
        </div>
    );
};
