import React, { FC } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import { INewsSpotlight } from 'src/interfaces';
import { getImageUrl } from 'src/utils/getImageUrl';
import { Link } from 'components/Link';
import { PageType } from 'src/enums/PageType';
import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { sortPostsByDate } from 'src/utils/sortPostsByDate';
import { LOCALES } from 'src/constants/locales';
import { LOCALE_REGEX } from 'src/constants/regexp';
import { translate } from 'src/utils/translate';
import { TranslationsEnum } from 'src/enums/TranslationsEnum';
import { PostCard } from '../Card';

import classes from './NewsSpotlight.module.scss';

const LATEST_POSTS_TO_SHOW = 2;

export const NewsSpotlight: FC<INewsSpotlight> = ({ backgroundImage, title, post, latestPosts, pageTypes }) => {
    const imageUrl = getImageUrl(backgroundImage.url);
    const location = useLocation();
    const postsToShow = sortPostsByDate(latestPosts.edges).slice(0, LATEST_POSTS_TO_SHOW);
    const localeFromUrl = LOCALE_REGEX.exec(location.pathname)?.[0] || LOCALES.EN;

    return (
        <div className={classes.container} style={{ backgroundImage: `url(${imageUrl})` }}>
            <h3 className={classes.title}> {title}</h3>

            <div className={classes.content__wrapper}>
                <div className={classes.content}>
                    <div className={classNames(classes.banner__wrapper)}>
                        <Link url={`/${post.locale}/${getPageSlugByType(PageType.news, pageTypes)}/${post.slug}`}>
                            {post.image && (
                                <img
                                    className={classNames(classes.banner)}
                                    src={getImageUrl(post.image.url)}
                                    alt={post.image.url}
                                />
                            )}
                            <div className={classNames(classes.imageHover)}>
                                <div className={classNames(classes.textBlock)}>
                                    <div>
                                        <span className={classNames(classes.label)}>
                                            {translate(localeFromUrl, post.type as TranslationsEnum)}
                                        </span>
                                        <span className={classNames(classes.date)}>
                                            {new Date(post.date ?? post.created_at).toLocaleDateString(post.locale)}
                                        </span>
                                    </div>
                                    <div className={classNames(classes.heading)}>{post.title}</div>
                                    <div className={classNames(classes.caption)}>{post.caption}</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={classes.latestNewsBlock}>
                    {postsToShow.map(({ node }) => (
                        <PostCard
                            key={node.id}
                            id={node.id}
                            label={translate(localeFromUrl, node.type as TranslationsEnum)}
                            date={new Date(node.date ?? node.published_at).toLocaleDateString(node.locale)}
                            title={node.title}
                            paragraph={node.section?.[0]?.richText.content}
                            readMore={translate(localeFromUrl, TranslationsEnum.read_more)}
                            to={`/${node.locale}/${getPageSlugByType(PageType.news, pageTypes)}/${node.slug}`}
                            shouldResize
                            className={classes.container__postCard}
                            classNameTitle={classes.container__postCard__title}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
