/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import classNames from 'classnames';

import { PageTitle } from 'components/PageTitle';
import { Block } from 'components/Block';
import { BulletList } from 'components/BulletList';
import { WhereToPlaySection } from 'components/WhereToPlaySection';
import { HowToPlaySection } from 'components/HowToPlaySection';
import { Banner } from 'components/Banner';

import { IGameInnerWithList, IPageTypes } from 'interfaces';
import { NextPreviousGames } from '.';

import classes from './index.module.scss';

interface IGameInnerProps {
    data: IGameInnerWithList;
    pageTypes: IPageTypes;
}

export const GameInner: FC<IGameInnerProps> = (props) => {
    const { name, block, benefits, videoBlock, whereToPlay, id, headerImage, banner } = props.data.currentGame;

    return (
        <div className={classes.GameInner}>
            <PageTitle image={headerImage[0]?.image}>{name}</PageTitle>
            <div className={classes.GameInner__spacingY}>
                <Block {...block[0]} />
            </div>
            <If condition={benefits.length}>
                <div className={classNames(classes.GameInner__spacingY, classes.GameInner__spacingX)}>
                    <BulletList
                        className={classes.GameInner__benefits}
                        data={{
                            id,
                            title: benefits[0].header,
                            list: benefits[0].bullets,
                            backgroundImage: benefits[0].backgroundImage,
                        }}
                    />
                </div>
            </If>
            <If condition={videoBlock.length}>
                <HowToPlaySection {...videoBlock[0]} />
            </If>
            <If condition={whereToPlay.length}>
                <WhereToPlaySection {...whereToPlay[0]} />
            </If>
            <If condition={banner.length}>
                <Banner {...banner[0]} />
            </If>

            <NextPreviousGames data={props.data} pageTypes={props.pageTypes} />
        </div>
    );
};
