const headers: Record<string, string> = {
    'Content-Type': 'application/json;charset=UTF-8',
};

interface IRequestObject {
    data?: unknown;
    options?: Partial<RequestInit>;
}

const request = <T>(url: string, requestObject: IRequestObject): Promise<T> =>
    fetch(`${STRAPI_API_URL}${url}`, {
        ...requestObject.options,
        headers: { ...headers, ...requestObject.options?.headers },
    })
        .then((response) => response.json())
        .catch((error) => {
            if (error instanceof Error) {
                throw error;
            }

            throw error.response ? error.response : { message: 'Request error' };
        });

export const http = {
    post: <T = unknown>(url: string, requestObject: IRequestObject = {}): Promise<T> =>
        request<T>(url, {
            ...requestObject,
            options: {
                ...requestObject.options,
                method: 'POST',
                body: JSON.stringify(requestObject.data),
            },
        }),
};
