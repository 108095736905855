import React from 'react';
import { Benefits } from 'components/Benefits';
import { Hero } from './Hero';
import { Block } from './Block';
import { Games } from './Games';
import { Posts } from './Posts';
import { Heading } from './Heading';
import { Contacts } from './Contacts';
import { PartnersList } from './PartnersList';
import { BulletList, VideoList, LIST_TYPES } from './List';
import { HtmlMarkdownContent } from './HtmlMarkdownContent';
import { EmployeeCardSection } from './EmployeeCardSection';
import { CardSection } from './CardSection';
import { VideoSection } from './VideoSection';
import { NewsSpotlight } from './NewsSpotlight';
import { HighlightsSection } from './HighlightsSection';
import { Banner } from './Banner';
import { WhereToPlaySection } from './WhereToPlaySection';
import { PositionsIntro } from './PositionsIntro';
import { TwainSportForm, ApplicationForm } from './forms';
import { BehindBetGamesSection } from './BehindBetGamesSection';
import { HowToPlaySection } from './HowToPlaySection';
import { PositionsModule } from './PositionsModule';
import { WhyBetGamesSection } from './WhyBetGamesSection';
import { LicensesSection } from './LicensesSection';
import { OurDna } from './OurDna';
import { RecruitmentSection } from './RecruitmentSection';
import { HeroVideo } from './HeroVideo';
import { TwainSportsIframeSection } from './TwainSportsIframeSection';

export const contentSectionMap: Record<string, { component: React.FC<any>; props?: string }> = {
    // TODO uncomment when starting building components
    'hero.hero': { component: Hero },
    'hero.hero-video': { component: HeroVideo },
    'layout.employee-card': { component: EmployeeCardSection },
    'layout.card-section': { component: CardSection },
    'layout.block': { component: Block },
    // 'layout.text-content': { component: MarkdownContent },
    'layout.games': { component: Games, props: 'games' },
    'layout.posts': { component: Posts, props: 'posts' },
    'layout.rich-text-content': { component: HtmlMarkdownContent },
    'layout.video-section': { component: VideoSection },
    // 'layout.stories': { component: Stories, props: 'stories' },
    // 'layout.heading': { component: Heading },
    'layout.contacts-content': { component: Contacts, props: 'emails' },
    'layout.benefits': { component: Benefits },
    'layout.heading': { component: Heading },
    'layout.partners': { component: PartnersList, props: 'partners' },
    'layout.news-spotlight': { component: NewsSpotlight, props: 'latestPosts' },
    'layout.highlights-section': { component: HighlightsSection },
    'layout.banner': { component: Banner },
    'layout.where-to-play-section': { component: WhereToPlaySection },
    'layout.behind-bet-games-section': { component: BehindBetGamesSection },
    'layout.how-to-play': { component: HowToPlaySection },
    'layout.positions-module': { component: PositionsModule, props: 'positions' },
    'layout.why-bet-games-section': { component: WhyBetGamesSection },
    'layout.licenses-section': { component: LicensesSection },
    'layout.recruitment-section': { component: RecruitmentSection },
    // LISTS
    [LIST_TYPES.bulletsAndIcons]: { component: BulletList },
    [LIST_TYPES.videoList]: { component: VideoList, props: 'global' },
    'layout.positions-intro': { component: PositionsIntro },
    // FORMS
    'forms.twain-sport-form': { component: TwainSportForm },
    'layout.awards': { component: PartnersList, props: 'awards' },
    'layout.our-dna': { component: OurDna },
    'forms.application-form': { component: ApplicationForm },
    'layout.twain-sports-iframe-section': { component: TwainSportsIframeSection },
};
