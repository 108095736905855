import React, { useState, FC } from 'react';
import { PostTypes } from 'enums/PostTypes';
import { IPost } from 'interfaces';
import { Filters } from 'components/Filters';
import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { sortPostsByDate } from 'src/utils/sortPostsByDate';
import { PageType } from 'src/enums/PageType';
import classes from './Posts.module.scss';
import { PostCard } from '../Card';

const NUMBER_OF_ELEMENTS_TO_SHOW = 6;

export const Posts: FC<IPost> = ({
    posts,
    typeAll,
    typeBusiness,
    typeEvents,
    typePartners,
    typeProduct,
    heading,
    readMoreText,
    loadMoreText,
    noPostsMessage,
    pageTypes,
}) => {
    const [filterValue, setFilterValue] = useState<PostTypes>(PostTypes.ALL);
    const [elementsToShow, setElementsToShow] = useState(NUMBER_OF_ELEMENTS_TO_SHOW);
    const typeTranslations: Record<string, string> = {
        [PostTypes.ALL]: typeAll,
        [PostTypes.BUSINESS]: typeBusiness,
        [PostTypes.EVENTS]: typeEvents,
        [PostTypes.PARTNERS]: typePartners,
        [PostTypes.PRODUCT]: typeProduct,
    };

    const numberOfPosts = sortPostsByDate(posts.edges).filter(
        ({ node }) => filterValue === PostTypes.ALL || node.type === filterValue,
    ).length;
    return (
        <div className={classes.container}>
            <h2 className={classes.heading}>{heading}</h2>
            <Filters
                filters={typeTranslations}
                onClick={(value: PostTypes) => {
                    setFilterValue(value);
                    setElementsToShow(NUMBER_OF_ELEMENTS_TO_SHOW);
                }}
                activeValue={filterValue}
            />
            {!numberOfPosts && <div className={classes.noPostsMessage}>{noPostsMessage}</div>}

            <div className={classes.postsContainer}>
                {posts.edges
                    .filter(({ node }) => filterValue === PostTypes.ALL || node.type === filterValue)
                    .map(
                        ({ node }, index: number) =>
                            index < elementsToShow && (
                                <PostCard
                                    key={node.id}
                                    id={node.id}
                                    label={typeTranslations[node.type]}
                                    date={new Date(node.date ?? node.published_at).toLocaleDateString(node.locale)}
                                    title={node.title}
                                    paragraph={node.section?.[0]?.richText.content}
                                    readMore={readMoreText}
                                    to={`/${node.locale}/${getPageSlugByType(PageType.news, pageTypes)}/${node.slug}`}
                                    className={classes.container__postCard}
                                />
                            ),
                    )}
            </div>
            {numberOfPosts > elementsToShow && (
                <button
                    className={classes.load_more}
                    onClick={() => setElementsToShow((prev) => prev + NUMBER_OF_ELEMENTS_TO_SHOW)}
                    type="button"
                >
                    {loadMoreText}
                </button>
            )}
        </div>
    );
};
