import React from 'react';
import { Link } from 'gatsby';

import { IImage } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';

import classes from './GamesBlock.module.scss';

interface IProps {
    image: IImage;
    typeName?: string;
    name: string;
    url: string;
}

export const GamesBlock: React.FC<IProps> = (props) => (
    <div className={classes.container}>
        <Link className={classes.anchor} to={props.url}>
            <If condition={!!props.image}>
                <div className={classes.image} style={{ backgroundImage: `url("${getImageUrl(props.image.url)}")` }} />
            </If>
            <h5 className={classes.type}>{props.typeName}</h5>
            <h3 className={classes.name}>{props.name}</h3>
        </Link>
    </div>
);
