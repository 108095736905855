import React from 'react';
import classNames from 'classnames';
import { IBanner } from 'interfaces';
import { TextColor } from 'enums/TextColor';
import { BannerBackgroundColor } from 'enums/BannerBackgroundColor';
import { getImageUrl } from 'utils/getImageUrl';
import { ButtonList } from 'components/ButtonList';
import classes from './Banner.module.scss';

interface IProps {
    data: IBanner;
}

const lightTextBackgrounds = [
    BannerBackgroundColor.SPACE,
    BannerBackgroundColor.TURQUOISE,
    BannerBackgroundColor.BLUE_ZODIAC,
    BannerBackgroundColor.GALAXY,
    BannerBackgroundColor.NEBULA,
];

export const Banner: React.FC<IProps> = (props) => {
    const data = props.data || props;

    const styles: React.CSSProperties = {
        backgroundColor: data.backgroundColor,
    };

    let isTextLight: boolean;

    if (data.backgroundImage) {
        styles.backgroundImage = `url("${getImageUrl(data.backgroundImage.url)}")`;
        isTextLight = data.textColor === TextColor.LIGHT;
    } else {
        isTextLight = lightTextBackgrounds.includes(data.backgroundColor);
    }

    return (
        <div className={classNames({ [classes.section]: !props.data })}>
            <div
                className={classNames(classes.container, classes[data.backgroundColor as string], {
                    [classes.lightText]: isTextLight,
                })}
                style={styles}
            >
                <h3 className={classes.title}>{data.title}</h3>
                <If condition={!!data.buttons?.length}>
                    <ButtonList buttons={data.buttons} className={classes.button} />
                </If>
            </div>
        </div>
    );
};
