import React from 'react';
import classNames from 'classnames';
import { IHeading } from 'interfaces/IHeading';
import { ISubheading } from 'interfaces/ISubheading';
import { IParagraphWithColor } from 'interfaces/IBlock';
import { HeadingSize } from 'enums/HeadingSize';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import classes from './Heading.module.scss';

interface IProps {
    heading: IHeading;
    subheadings?: ISubheading[];
    className?: string;
    paragraphWithColor: IParagraphWithColor[];
}

const headingElements = {
    [HeadingSize.H2]: 'h2',
    [HeadingSize.H3]: 'h3',
    [HeadingSize.H4]: 'h4',
};

const parseColorHex = (colorString: string): string => JSON.parse(colorString)?.hex || '#000000';

export const Heading: React.FC<IProps> = (props) => (
    <div className={classNames(props.className)} style={{ textAlign: props.heading?.textAlign }}>
        <If condition={!!props.heading.label}>
            <span className={classes.label}>{props.heading.label}</span>
        </If>
        {React.createElement(headingElements[props.heading.size] || headingElements[HeadingSize.H2], {
            dangerouslySetInnerHTML: { __html: props.heading.title },
        })}
        <div className={classNames(props.subheadings?.length ? classes.contentRow : classes.content)}>
            <div>
                <HtmlMarkdownContent
                    className={classes.block}
                    content={props.heading.description}
                    withSpacing={false}
                />
                {props.paragraphWithColor?.map(({ colorpicker, fontSize, text, italic }) => (
                    <HtmlMarkdownContent
                        style={{ color: parseColorHex(colorpicker), fontSize: `${fontSize}px` }}
                        content={italic ? `<i>${text}</i>` : text}
                        withSpacing={false}
                    />
                ))}
            </div>
            <If condition={!!props.subheadings?.length}>
                <div className={classes.subHeadings}>
                    {props.subheadings?.map((subheading) => (
                        <div key={subheading.id} className={classes.block}>
                            <div className={classNames(classes.label, classes.subLabel)}>{subheading.label}</div>
                            <HtmlMarkdownContent content={subheading.description} withSpacing={false} />
                        </div>
                    ))}
                </div>
            </If>
        </div>
    </div>
);
