import React, { FC } from 'react';
import { Image } from 'components/Image';
import { Link } from 'components/Link';
import { IPartner } from 'interfaces';
import classes from './PartnersList.module.scss';

export const Partner: FC<IPartner> = ({ url, logo }) => (
    <>
        <If condition={!!url}>
            <Link url={url} newTab className={classes.link}>
                <Image image={logo} className={classes.link__image} />
            </Link>
        </If>
        <If condition={!url}>
            <Image image={logo} className={classes.img} />
        </If>
    </>
);
