import React, { FC } from 'react';
import { getImageUrl } from 'utils/getImageUrl';
import { IImage } from 'interfaces';
import { Card, ICard } from './Card';
import classes from './BehindBetGamesSection.module.scss';

interface IProps {
    behindBetGamesCards: ICard[];
    heading: string;
    image: IImage;
}

export const BehindBetGamesSection: FC<IProps> = ({ heading, behindBetGamesCards, image }) => (
    <div className={classes.section}>
        <h2 className={classes.section__header}>{heading}</h2>
        <div className={classes.section__content}>
            <img src={getImageUrl(image.url)} className={classes.section__content__image} alt={image.name} />
            <div className={classes.section__content__cards}>
                {behindBetGamesCards.map(({ heading: cardHeading, content, id }) => (
                    <Card id={id} key={id} heading={cardHeading} content={content} />
                ))}
            </div>
        </div>
    </div>
);
