import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { IInputDefault } from 'interfaces';
import uploadIcon from 'images/inputUpload.svg';
import trashIcon from 'images/trashCan.svg';

import classes from './index.module.scss';

export const FileInput: FC<IInputDefault> = ({ className, placeholder, register, name, error, resetField }) => {
    const [fileName, setFileName] = useState<string>();
    const registerField = register(name);
    return (
        <>
            <label
                className={classNames(classes.Input__field, className, classes.Input__file, {
                    [classes.invalid]: !!error,
                    [classes.Input__field__visible]: !fileName,
                })}
                htmlFor="file"
            >
                <span>{fileName || placeholder}</span>
                <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...registerField}
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        registerField.onChange(e);
                        setFileName(e.target.files ? e.target.files[0]?.name : undefined);
                    }}
                />
            </label>
            <button
                type="button"
                onClick={() => {
                    if (fileName && resetField) {
                        resetField(name);
                        setFileName(undefined);
                    }
                }}
                className={classes.Input__container__image}
            >
                <img src={fileName ? trashIcon : uploadIcon} alt="upload" />
            </button>
        </>
    );
};
