import React, { FC, useMemo } from 'react';

import { Filters } from 'components/Filters';
import { IPageTypes } from 'src/interfaces';
import { Card, IPosition } from './Card';
import classes from './PositionsModule.module.scss';

interface IProps {
    allSectionsText: string;
    id: number;
    positions: { edges: IPosition[] };
    title: string;
    pageTypes: IPageTypes;
}

export const PositionsModule: FC<IProps> = ({ positions, allSectionsText, title, pageTypes }) => {
    const getUniqueCategories = (pos: { edges: IPosition[] }): string[] => {
        const uniqueCategories = pos.edges
            .map(({ node }) => node.position_category?.name)
            .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);

        uniqueCategories.unshift(allSectionsText);
        return uniqueCategories;
    };
    const getFilterValues = (uniqueCategories: string[]): Record<string, string> =>
        uniqueCategories.reduce((filterObject: Record<string, string>, value: string) => {
            if (value) {
                filterObject[value] = value;
            }
            return filterObject;
        }, {});

    const uniqueCategories = useMemo(() => getUniqueCategories(positions), [positions]);
    const filterValuesObject = useMemo(() => getFilterValues(uniqueCategories), [uniqueCategories]);

    const [filterValue, setFilterValue] = React.useState<string>(allSectionsText);
    const selected = positions.edges.filter(({ node }) => node.position_category?.name === filterValue);

    return (
        <div className={classes.positions}>
            <h2 className={classes.positions__heading}>{title}</h2>
            <Filters
                buttonClasses={classes.positions__filter}
                filters={filterValuesObject}
                onClick={setFilterValue}
                activeValue={filterValue}
            />
            <div className={classes.positions__content}>
                {filterValue !== allSectionsText && (
                    <Card pageTypes={pageTypes} heading={filterValue} positions={selected} />
                )}
                {filterValue === allSectionsText &&
                    uniqueCategories.map(
                        (category) =>
                            allSectionsText !== category && (
                                <Card
                                    key={category}
                                    heading={category}
                                    positions={positions.edges.filter(
                                        ({ node }) => node.position_category?.name === category,
                                    )}
                                    pageTypes={pageTypes}
                                />
                            ),
                    )}
            </div>
        </div>
    );
};
