import React, { FC } from 'react';
import classNames from 'classnames';
import { IWhyBetGames } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';

import classes from './WhyBetGamesSection.module.scss';

export const WhyBetGamesSection: FC<IWhyBetGames> = ({ heading, listWithIcons, image, reverseOrder }) => (
    <div className={classes.section}>
        <h2 className={classes.section__heading}>{heading}</h2>
        <div className={classNames(classes.section__content, { [classes.section__reverse]: reverseOrder })}>
            <div className={classes.section__content__list}>
                {listWithIcons.map(({ text, icon, id }) => (
                    <div className={classes.section__content__list__item} key={id}>
                        <div className={classes.section__content__list__item__icon}>
                            <img src={getImageUrl(icon.url)} alt={icon.name} />
                        </div>
                        <p className={classes.section__content__list__item__text}>{text}</p>
                    </div>
                ))}
            </div>
            <div className={classes.section__content__wrapper}>
                <img className={classes.section__content__image} src={getImageUrl(image.url)} alt={image.name} />
            </div>
        </div>
    </div>
);
