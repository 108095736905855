import React, { FC } from 'react';
import classNames from 'classnames';

import { IInput } from 'interfaces';

import classes from './index.module.scss';

export const Input: FC<IInput> = ({ type, className, placeholder, register, name, error }) => (
    <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name)}
        className={classNames(classes.Input__field, className, {
            [classes.invalid]: !!error,
        })}
        placeholder={placeholder}
        type={type}
    />
);
