import React from 'react';
import classNames from 'classnames';
import classes from './Filters.module.scss';

interface IProps {
    filters: Record<string, string>;
    activeValue: string;
    onClick(value: unknown): void;
    buttonClasses?: string;
}

export const Filters: React.FC<IProps> = (props) => (
    <div className={classes.container}>
        {Object.keys(props.filters).map((value) => (
            <button
                type="button"
                onClick={() => props.onClick(value)}
                key={value}
                className={classNames(classes.button, props.buttonClasses, {
                    [classes.selected]: value === props.activeValue,
                })}
            >
                {props.filters[value]}
            </button>
        ))}
    </div>
);
