import React, { useContext, useRef } from 'react';
import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { navigate } from 'gatsby';
import { IContactForm, IEmail } from 'interfaces';
import Reaptcha from 'reaptcha';
import { Input } from 'components/Contacts/Input';
import { Button } from 'components/Button';
import { IFormInput } from 'interfaces/IContactForm';
import { Filters } from 'components/Filters';
import { Loader } from 'components/Loader';
import { Message } from 'components/Contacts/Message';
import { useMessage } from 'components/Contacts/hooks/useMessage';
import { IMessage } from 'components/Contacts/interfaces';
import { EMAIL_REGEXP } from 'constants/regexp';
import { http } from 'utils/http';
import { LayoutContext } from 'src/templates/Layout/Layout';
import classes from './Form.module.scss';

enum FormFieldType {
    EMAILFROM = 'emailFrom',
    SUBJECT = 'subject',
    MESSAGE = 'message',
}

const requiredFields: FormFieldType[] = [FormFieldType.EMAILFROM, FormFieldType.MESSAGE];

interface IInitialFieldState {
    touched: boolean;
    value: string;
    error?: string;
}

const initialFieldState: IInitialFieldState = {
    touched: false,
    value: '',
    error: undefined,
};

const initialState = {
    [FormFieldType.EMAILFROM]: initialFieldState,
    [FormFieldType.SUBJECT]: initialFieldState,
    [FormFieldType.MESSAGE]: initialFieldState,
};

type IFormState = Record<FormFieldType, IInitialFieldState>;

interface IProps {
    emails: IGatsbyConnection<IEmail>;
    data: IContactForm;
    locale: string;
    recaptchaV2Key: string;
}

export const Form: React.FC<IProps> = (props) => {
    const recaptchaRef = useRef<Reaptcha | null>();

    const [isFetching, updateFetching] = React.useState(false);
    const { message, updateMessage, resetMessage } = useMessage();
    const [emailToValue, setEmailToValue] = React.useState<string>(`${props.emails.edges[0].node.id}`);
    const [formState, updateFormState] = React.useState<IFormState>(initialState);
    const context = useContext(LayoutContext);

    const updateInput = (field: string, e: React.ChangeEvent<HTMLInputElement>): void => {
        updateFormState({
            ...formState,
            [field]: {
                value: e.target.value,
                touched: true,
                error: undefined,
            },
        });
    };

    const validateInput = (field: FormFieldType): void => {
        const inputState = formState[field];

        if (requiredFields.includes(field) && !inputState.value.length) {
            updateFormState({
                ...formState,
                [field]: {
                    ...inputState,
                    error: (props.data[field] as IFormInput).errorIsRequired,
                },
            });

            return;
        }

        if (field === FormFieldType.EMAILFROM && !EMAIL_REGEXP.test(inputState.value)) {
            updateFormState({
                ...formState,
                [field]: {
                    ...inputState,
                    error: (props.data[field] as IFormInput).errorIsNotValid,
                },
            });
        }
    };

    const onSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        if (!recaptchaRef.current) {
            return;
        }

        recaptchaRef.current.execute();
    };

    const onCaptchaVerified = (): void => {
        const formValue = {
            emailFrom: formState.emailFrom.value,
            subject: formState.subject.value,
            message: formState.message.value,
            emailTo: Number(emailToValue),
        };

        updateFetching(true);
        resetMessage();
        http.post<IMessage>('/contact-form-submissions', {
            data: formValue,
        })
            .then((response) => {
                if (response.error) {
                    updateMessage({ error: `${props.data.messageError} ${response.error}` });
                } else {
                    updateFormState(initialState);
                    navigate(`/${props.locale}/${context.thankYouPageSlug}`, { replace: true });
                }
            })
            .catch((e) => {
                updateMessage({ error: e.toString() });
            })
            .finally(() => {
                updateFetching(false);
            });
    };

    const formInvalid = React.useMemo(
        (): boolean => !!requiredFields.find((field) => !formState[field].touched || !!formState[field].error),
        [formState],
    );

    const filters = props.emails.edges.reduce((filterObj, email) => {
        filterObj[email.node.id] = email.node.title;
        return filterObj;
    }, {} as Record<IEmail['id'], IEmail['title']>);

    return (
        <form onSubmit={onSubmit}>
            <If condition={!!props.emails}>
                <Filters filters={filters} onClick={setEmailToValue} activeValue={emailToValue} />
            </If>
            {Object.values(FormFieldType).map((field) => {
                const inputProps = props.data[field];
                const inputState = formState[field];

                return (
                    <div className={classes.formRow} key={field}>
                        <label htmlFor={field}>
                            <div className={classes.name}>{inputProps.title}</div>
                            <Input
                                name={field}
                                type={inputProps.type}
                                placeholder={inputProps.placeholder}
                                isRequired={requiredFields.includes(field)}
                                onChange={(e) => updateInput(field, e)}
                                onBlur={() => validateInput(field)}
                                isError={!!inputState.error}
                                value={inputState.value}
                            />
                            <If condition={!!inputState.error}>
                                <div className={classes.error}>{inputState.error}</div>
                            </If>
                        </label>
                    </div>
                );
            })}

            <div className={classes.submitRow}>
                <Button buttonType="submit" disabled={formInvalid || isFetching} type={props.data.button.type}>
                    {props.data.button?.text}
                </Button>

                <If condition={isFetching}>
                    <Loader className={classes.loader} />
                </If>
            </div>
            <If condition={!!message.error}>
                <Message message={message} />
            </If>

            {typeof window !== 'undefined' && (
                <Reaptcha
                    ref={(e) => {
                        recaptchaRef.current = e;
                    }}
                    sitekey={props.recaptchaV2Key}
                    onVerify={onCaptchaVerified}
                    size="invisible"
                />
            )}
        </form>
    );
};
