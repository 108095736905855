import React, { FC } from 'react';
import { IDefaultCard } from 'interfaces';
import classNames from 'classnames';
import { getImageUrl } from 'utils/getImageUrl';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { getTextSizeClass } from 'src/utils/getTextSizeClass';
import classes from './Card.module.scss';

export const DefaultCard: FC<IDefaultCard> = ({ icon, label, title, text, textSize }) => (
    <div className={classNames(classes.container)}>
        <div className={classNames(classes.icon_wrapper)}>
            <img className={classNames(classes.icon)} src={getImageUrl(icon.url)} alt={icon.name} />
        </div>
        <p className={classNames(classes.label)}>{label}</p>
        <h3 className={classNames(classes.heading)}>{title}</h3>

        <div className={classNames(classes.text)}>
            <HtmlMarkdownContent className={getTextSizeClass(textSize)} withSpacing={false} content={text} />
        </div>
    </div>
);
