import React, { FC } from 'react';
import { ILicensesSection } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';
import classes from './LicensesSection.module.scss';

export const LicensesSection: FC<ILicensesSection> = ({
    background,
    mainImage,
    topLeftImage,
    topRightImage,
    heading,
    paragraph,
}) => (
    <div
        className={classes.licenses}
        style={{
            backgroundImage: `url("${getImageUrl(background.url)}")`,
        }}
    >
        <h2 className={classes.licenses__heading}>{heading}</h2>
        <p className={classes.licenses__paragraph}>{paragraph}</p>
        <div className={classes.licenses__images}>
            <div className={classes.licenses__images__topImages}>
                <img
                    className={classes.licenses__images__topImages__left}
                    src={getImageUrl(topLeftImage.url)}
                    alt={topLeftImage.name}
                />
                <img
                    className={classes.licenses__images__topImages__right}
                    src={getImageUrl(topRightImage.url)}
                    alt={topRightImage.name}
                />
            </div>
            <div className={classes.licenses__images__mainImage}>
                <img src={getImageUrl(mainImage.url)} alt={mainImage.name} />
            </div>
        </div>
    </div>
);
