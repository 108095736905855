import React, { FC } from 'react';

import { IListBase } from 'src/interfaces';

import classes from './index.module.scss';

export const Base: FC<IListBase> = ({ header, children }) => (
    <div className={classes.List}>
        {header && <h2 className={classes.List__header}>{header}</h2>}
        {children}
    </div>
);
