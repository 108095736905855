import React from 'react';
import { MapContainer, TileLayer, ZoomControl, Marker, Popup } from 'react-leaflet';
import { Map as LeafletMap, latLngBounds, LatLngExpression } from 'leaflet';
import { debounce } from 'lodash-es';
import { isDomAvailable } from 'utils/isDomAvailable';
import 'leaflet/dist/leaflet.css';
import { MarkerIcon } from './MarkerIcon';
import classes from './Map.module.scss';

const isDomAvailableValue = isDomAvailable();
const officeVilnius: LatLngExpression = [54.7004672, 25.2593297];
const officeMalta: LatLngExpression = [35.8955886, 14.4739321];
const officeRegus: LatLngExpression = [-33.933310575882935, 18.47085219957167];
const mapGroup = isDomAvailableValue ? latLngBounds([officeMalta, officeVilnius, officeRegus]) : [];

const tilesSettings = {
    attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    maxZoom: 20,
};

interface IProps {
    address1?: string;
    address2?: string;
    address3?: string;
}

export const Map: React.FC<IProps> = (props) => {
    const mapState = React.useRef<LeafletMap>();

    const fitBounds = React.useCallback((): void => {
        mapState.current?.fitBounds(mapGroup, { padding: [30, 50] });
    }, [mapState]);

    React.useEffect(() => {
        const debounceResize = debounce(fitBounds, 300);
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    const onReady = React.useCallback((map: LeafletMap): void => {
        mapState.current = map;
        fitBounds();
    }, []);

    if (!isDomAvailableValue) {
        return null;
    }
    const mapIcon = MarkerIcon();

    return (
        <MapContainer
            whenCreated={onReady}
            className={classes.container}
            center={officeVilnius}
            zoom={13}
            zoomSnap={0.1}
            scrollWheelZoom={false}
            zoomControl={false}
        >
            <TileLayer
                url={tilesSettings.url}
                attribution={tilesSettings.attribution}
                maxZoom={tilesSettings.maxZoom}
            />
            <Marker icon={mapIcon} position={officeVilnius}>
                <If condition={!!props.address1}>
                    <Popup>{props.address1}</Popup>
                </If>
            </Marker>
            <Marker icon={mapIcon} position={officeMalta}>
                <If condition={!!props.address2}>
                    <Popup>{props.address2}</Popup>
                </If>
            </Marker>
            <Marker icon={mapIcon} position={officeRegus}>
                <If condition={!!props.address3}>
                    <Popup>{props.address3}</Popup>
                </If>
            </Marker>
            <ZoomControl position="bottomright" />
        </MapContainer>
    );
};
