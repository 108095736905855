import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { ITwainSportsIframeSection } from 'interfaces';

import classes from './TwainSportsIframeSection.module.scss';

const CONTAINER_SIZES = {
    DESKTOP: '1170px',
    TABLET: '750px',
    MOBILE: '350px',
    RESPONSIVE: '100%',
};

export const TwainSportsIframeSection: FC<ITwainSportsIframeSection> = ({ link }) => {
    const iFrame = useRef<HTMLIFrameElement>(null);

    const [containerSize, setContainerSize] = useState(CONTAINER_SIZES.RESPONSIVE);

    const [cookieValue, setCookieValue] = useState<number>();
    const [linkWithCookie, setLinkWithCookie] = useState<string>();

    function checkIfCookieExists(cookieName: string): { cookieExists: boolean; existingCookieValue?: number } {
        const ALL_COOKIES = decodeURIComponent(document.cookie).split(';');
        let cookieExists = false;
        let existingCookieValue;

        ALL_COOKIES.forEach((cookie) => {
            if (cookie.includes(cookieName)) {
                const value = cookie.split('=')[1];

                cookieExists = true;
                existingCookieValue = value;
            }
        });
        return { cookieExists, existingCookieValue };
    }

    const scrollSubscribe = useCallback(() => {
        if (iFrame.current && iFrame.current.contentWindow) {
            iFrame.current?.contentWindow.postMessage(
                JSON.stringify({
                    type: 'onScroll',
                    payload: {
                        height: window.innerHeight,
                        scrollY: window.scrollY,
                        iFrameTopOffset: iFrame.current.offsetTop,
                    },
                }),
            );
        }
    }, [iFrame]);

    const messageHandler = useCallback(
        (message) => {
            try {
                const parsedData = JSON.parse(message.data);

                if (iFrame.current && iFrame.current.contentWindow) {
                    switch (parsedData.type) {
                        case 'subscribeScroll': {
                            window.addEventListener('scroll', scrollSubscribe);
                            break;
                        }
                        case 'unsubscribeScroll': {
                            window.removeEventListener('scroll', scrollSubscribe);
                            break;
                        }
                        case 'newHeight': {
                            iFrame.current.style.height = `${parsedData.payload.height}px`;
                            break;
                        }
                        case 'getViewPort': {
                            iFrame.current?.contentWindow.postMessage(
                                JSON.stringify({
                                    type: 'getViewPort',
                                    payload: {
                                        width: window.innerWidth,
                                        height: window.innerHeight,
                                        iFrameTopOffset: iFrame.current.offsetTop,
                                        scrollY: window.scrollY,
                                    },
                                }),
                                '*',
                            );
                            break;
                        }
                        case 'scrollTo': {
                            window.scroll(0, Number(parsedData.payload.scrollTo));
                            break;
                        }
                        case 'iframeTop': {
                            window.scroll(0, Number(parsedData.payload.topOffset) + iFrame.current.offsetTop);
                            break;
                        }
                        default:
                    }
                }
            } catch (e) {}
        },
        [iFrame, scrollSubscribe],
    );

    useEffect(() => {
        if (checkIfCookieExists('bgtv-demo-token').cookieExists === true) {
            setCookieValue(checkIfCookieExists('bgtv-demo-token').existingCookieValue);
        }

        if (checkIfCookieExists('bgtv-demo-token').cookieExists === false) {
            const randomNumber = Date.now() + Math.floor(Math.random() * 100000);
            const SECONDS_IN_DAY = 60 * 60 * 24;

            document.cookie = `bgtv-demo-token=${randomNumber}; max-age=${SECONDS_IN_DAY * 2}`;
            setCookieValue(checkIfCookieExists('bgtv-demo-token').existingCookieValue);
        }

        if (cookieValue !== undefined) {
            setLinkWithCookie(`${link}&token=bgtv-demo-token-${cookieValue}`);
        }
    }, [cookieValue]);

    useEffect(() => {
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, []);

    return (
        <div className={classes.sectionContainer}>
            <div className={classes.buttonContainer}>
                <button
                    className={classes.breakPointButton}
                    type="button"
                    onClick={() => setContainerSize(CONTAINER_SIZES.DESKTOP)}
                >
                    DESKTOP
                </button>
                <button
                    className={classes.breakPointButton}
                    type="button"
                    onClick={() => setContainerSize(CONTAINER_SIZES.TABLET)}
                >
                    TABLET
                </button>
                <button
                    className={classes.breakPointButton}
                    type="button"
                    onClick={() => setContainerSize(CONTAINER_SIZES.MOBILE)}
                >
                    MOBILE
                </button>
                <button
                    className={classes.breakPointButton}
                    type="button"
                    onClick={() => setContainerSize(CONTAINER_SIZES.RESPONSIVE)}
                >
                    RESPONSIVE
                </button>
            </div>
            <div className={classes.flexContainer}>
                <div className={classes.responsiveContainer} style={{ maxWidth: containerSize }}>
                    <div className={classes.container}>
                        <iframe
                            ref={iFrame}
                            className={classes.container__iframe}
                            id="twainsport_iframe"
                            src={linkWithCookie}
                            title="twain sports"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
