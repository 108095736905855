import React from 'react';
import classNames from 'classnames';
import { ILink } from 'interfaces';
import { Button } from 'components/Button';
import classes from './ButtonList.module.scss';

interface IProps {
    buttons: ILink[];
    className?: string;
}

export const ButtonList: React.FC<IProps> = (props) => (
    <>
        {props.buttons.map((button, index) => (
            <>
                <Button
                    key={index}
                    className={classNames(classes.button, props.className)}
                    url={button.url}
                    newTab={button.newTab}
                    type={button.type}
                >
                    {button.text}
                </Button>
            </>
        ))}
    </>
);
