import React, { FC } from 'react';
import classNames from 'classnames';
import { IEmployeeCardSection } from 'interfaces';
import classes from './EmployeeCardSection.module.scss';
import { EmployeeCard } from '../Card';

export const EmployeeCardSection: FC<IEmployeeCardSection> = ({ title, id, icon, label, name, email, url, newTab }) => (
    <>
        <br />
        <div className={classNames(classes.container)}>
            <h2 className={classNames(classes.heading)}>{title}</h2>
            <div className={classNames(classes.text_block_wrapper)}>
                <EmployeeCard
                    url={url}
                    key={id}
                    icon={icon}
                    id={id}
                    label={label}
                    email={email}
                    name={name}
                    newTab={newTab}
                />
            </div>
        </div>
    </>
);
