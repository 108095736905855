import React, { FC } from 'react';
import classNames from 'classnames';

import classes from './Popup.module.scss';

interface IProps {
    className?: string;
}

export const Popup: FC<IProps> = (props) => (
    <div className={classNames(classes.overlay, props.className)}>
        <div className={classes.container}>{props.children}</div>
    </div>
);
