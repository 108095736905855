import React, { FC } from 'react';
import { Link } from 'components/Link';
import image from 'images/careerArrow.svg';
import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { PageType } from 'src/enums/PageType';
import { IPageTypes } from 'src/interfaces';
import classes from './Card.module.scss';

export interface IPosition {
    node: {
        id: string;
        location: string;
        position_category: {
            name: string;
        };
        title: string;
        locale: string;
        slug: string;
    };
}

interface IProps {
    heading: string;
    positions: IPosition[];
    pageTypes: IPageTypes;
}

export const Card: FC<IProps> = ({ heading, positions, pageTypes }) => (
    <div className={classes.card}>
        <h3 className={classes.card__heading}>{heading}</h3>
        <div className={classes.card__positions}>
            {positions.map(({ node }) => (
                <Link
                    key={node.id}
                    className={classes.card__positions__item}
                    url={`/${node?.locale}/${getPageSlugByType(PageType.career, pageTypes)}/${node?.slug}`}
                >
                    <span className={classes.card__positions__item__text}>
                        <span className={classes.card__positions__item__text__position}>{node.title}</span>
                        <span className={classes.card__positions__item__text__location}>{node.location}</span>
                    </span>
                    <img src={image} alt="arrow right" />
                </Link>
            ))}
        </div>
    </div>
);
