import React from 'react';
import classNames from 'classnames';
import { ICardSection } from 'interfaces';
import classes from './CardSection.module.scss';
import { DefaultCard, CardWithColumns } from '../Card';

export const CardSection: React.FC<ICardSection> = ({ heading, card, cardWithColumns }) => (
    <div className={classNames(classes.container)}>
        <h2 className={classNames(classes.heading)}>{heading}</h2>
        <div className={classNames(classes.text_block_wrapper)}>
            {card.map(({ icon, id, label, text, title, textSize }) => (
                <DefaultCard key={id} icon={icon} id={id} label={label} text={text} title={title} textSize={textSize} />
            ))}
        </div>
        <div className={classNames(classes.text_block_wrapper)}>
            {cardWithColumns.map(({ icon, id, label, columnList, heading: _heading, textSize }) => (
                <CardWithColumns
                    key={id}
                    icon={icon}
                    id={id}
                    label={label}
                    columnList={columnList}
                    heading={_heading}
                    textSize={textSize}
                />
            ))}
        </div>
    </div>
);
