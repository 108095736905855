import React, { FC } from 'react';

import { getImageUrl } from 'utils/getImageUrl';
import { IHowToPlay } from 'interfaces';
import { Button } from 'components/Button';
import classes from './HowToPlaySection.module.scss';
import { Video } from '../Video';

export const HowToPlaySection: FC<IHowToPlay> = ({ background, video, buttonLink, buttonText, newTab }) => (
    <div
        className={classes.container}
        style={{
            backgroundImage: `url(${getImageUrl(background.url)})`,
        }}
    >
        <div className={classes.container__section}>
            <h2 className={classes.container__section__heading}>{video.title}</h2>
            <Video
                light={video.thumbnail && getImageUrl(video.thumbnail?.url)}
                link={video.link}
                autoplayWhenVisible={false}
                className={classes.container__section__player}
                isPlayIcon={video.playIcon}
            />
        </div>
        <div className={classes.container__section__button}>
            <Button url={buttonLink} newTab={newTab}>
                {buttonText}
            </Button>
        </div>
    </div>
);
