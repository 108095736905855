import React, { FC } from 'react';

import { Video } from 'components/Video';
import { IVideoList } from 'src/interfaces';
import { Base } from './Base';
import { getImageUrl } from 'utils/getImageUrl';

import classes from './index.module.scss';

interface IVideoListMap {
    id: number;
    items: { link: string; title: string; thumbnail: { url: string }; playIcon: boolean };
}

export const VideoList: FC<IVideoList> = ({ columns, header }) => (
    <Base {...{ header }}>
        <div className={classes.List__videoList}>
            {columns.map(({ id, items }: IVideoListMap) => {
                return (
                    <div className={classes.List__videoList__videoBlock}>
                        <Video
                            key={id}
                            link={items.link}
                            title={items.title}
                            className={classes.List__videoList__player}
                            light={items.thumbnail && getImageUrl(items.thumbnail?.url)}
                            isPlayIcon={items.playIcon}
                        />
                    </div>
                );
            })}
        </div>
    </Base>
);
