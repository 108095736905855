import classNames from 'classnames';
import React, { FC } from 'react';
import { getImageUrl } from 'src/utils/getImageUrl';
import { IHighlightsCard } from 'interfaces';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { Link } from 'src/components/Link';
import { useLocation } from '@reach/router';
import { LOCALE_REGEX, URL_REGEX } from 'src/constants/regexp';
import classes from './Card.module.scss';

export const Card: FC<IHighlightsCard> = ({ content, heading, url, image, moreInfoText }) => {
    const { pathname } = useLocation();
    const locale = LOCALE_REGEX.exec(pathname)?.[0];
    const link = url && URL_REGEX.test(url) ? url : `${locale || 'en'}/${url}`;

    const imageContext = () => (
        <img
            className={classNames(classes.card__content__link__image)}
            src={getImageUrl(image.url)}
            alt="carousel content"
        />
    );

    const descriptionContext = () => (
        <>
            <h3 className={classNames(classes.card__content__text__heading)}>{heading}</h3>
            <p className={classNames(classes.card__content__text__paragraph)}>
                <HtmlMarkdownContent withSpacing={false} content={content} />
            </p>
        </>
    );

    return (
        <div className={classNames(classes.card)}>
            <div className={classNames(classes.card__content)}>
                {url ? (
                    <>
                        <Link
                            url={link}
                            className={classNames(classes.card__content__link)}
                            newTab={URL_REGEX.test(url)}
                        >
                            {imageContext()}
                        </Link>
                        <Link
                            url={link}
                            className={classNames(classes.card__content__text)}
                            newTab={URL_REGEX.test(url)}
                        >
                            {descriptionContext()}
                            <span className={classNames(classes.card__content__text__url)}>{moreInfoText}</span>
                        </Link>
                    </>
                ) : (
                    <>
                        <div className={classNames(classes.card__content__link)}>{imageContext()}</div>
                        <div className={classNames(classes.card__content__text_no_url)}>{descriptionContext()}</div>
                    </>
                )}
            </div>
        </div>
    );
};
