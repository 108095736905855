import React, { FC } from 'react';
import classNames from 'classnames';

import { BulletList } from 'components/BulletList';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { IPositionsIntro } from 'interfaces';

import classes from './index.module.scss';

export const PositionsIntro: FC<IPositionsIntro> = ({ header, description, label, column1, column2 }) => (
    <div className={classes.PositionsIntro}>
        <div className={classes.PositionsIntro__firstColumn}>
            <div className={classes.PositionsIntro__firstColumn__intro}>
                <p className={classes.PositionsIntro__firstColumn__intro__label}>{label}</p>
                <h2>{header}</h2>

                <HtmlMarkdownContent
                    className={classes.PositionsIntro__firstColumn__intro__description}
                    content={description}
                    withSpacing={false}
                />
            </div>

            {column1.map(({ id, header: _header, bullets }) => (
                <BulletList
                    key={id}
                    className={classNames(classes.PositionsIntro__firstColumn__list)}
                    data={{ id, title: _header, list: bullets }}
                />
            ))}
        </div>
        <div className={classes.PositionsIntro__secondColumn}>
            <BulletList
                className={classes.PositionsIntro__secondColumn__list}
                data={{
                    id: column2.id,
                    title: column2.header,
                    list: column2.bullets,
                    backgroundImage: column2.backgroundImage,
                }}
            />
        </div>
    </div>
);
