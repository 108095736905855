import React, { FC } from 'react';
import { getImageUrl } from 'utils/getImageUrl';
import { Button } from 'components/Button';
import { IWhereToPlayCard } from 'interfaces';
import classes from './Card.module.scss';

export const Card: FC<IWhereToPlayCard> = ({ buttonText, description, image, link, newTab }) => (
    <div className={classes.card}>
        <img className={classes.card__image} src={getImageUrl(image?.url)} alt="card" />
        <div className={classes.card__text}>
            <p className={classes.card__text__description}>{description}</p>
            {link && (
                <Button className={classes.card__text__button} url={link} newTab={newTab}>
                    {buttonText}
                </Button>
            )}
        </div>
    </div>
);
