import React from 'react';
import classNames from 'classnames';
import classes from './Loader.module.scss';

export interface IProps {
    className?: string;
}

// Will be replaced later with new logo+loader
export const Loader: React.FC<IProps> = (props) => (
    <span className={classNames(classes.container, props.className)}>
        <span className={classes.content} />
    </span>
);
