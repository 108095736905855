import React, { FC } from 'react';
import classNames from 'classnames';

import { IRecruitmentSection } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';

import classes from './RecruitmentSection.module.scss';

export const RecruitmentSection: FC<IRecruitmentSection> = ({ heading, recruitmentCards }) => (
    <div className={classes.section}>
        <h2 className={classes.section__heading}>{heading}</h2>
        <div className={classNames(classes.section__content)}>
            {recruitmentCards.map(({ heading: _heading, description, icon, id }) => (
                <div className={classes.section__content__card} key={id}>
                    <div className={classes.section__content__card__icon}>
                        <img src={getImageUrl(icon.url)} alt={icon.name} />
                    </div>
                    <h4 className={classes.section__content__card__heading}>{_heading}</h4>
                    <p className={classes.section__content__card__description}>{description}</p>
                </div>
            ))}
        </div>
    </div>
);
