import React, { FC } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { sortPostsByDate } from 'src/utils/sortPostsByDate';
import { PageType } from 'src/enums/PageType';
import { LOCALE_REGEX } from 'src/constants/regexp';
import { LOCALES } from 'src/constants/locales';
import { PageTitle } from 'components/PageTitle';
import { getImageUrl } from 'utils/getImageUrl';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import { IPostInner } from 'interfaces';
import { Link } from 'components/Link';
import { Banner } from 'components/Banner';
import { translate } from 'src/utils/translate';
import { TranslationsEnum } from 'src/enums/TranslationsEnum';
import { PostCard } from '../Card';
import { EmployeeCardSection } from '../EmployeeCardSection';
import classes from './PostInner.module.scss';

const MAX_POSTS_TO_SHOW = 4;

export const PostInner: FC<IPostInner> = ({
    pageTitle,
    image,
    title,
    label,
    authorCard,
    caption,
    sections,
    latestPosts,
    latestNewsHeader,
    newsLink,
    headerImage,
    banner,
    pageTypes,
    date,
    locale,
}) => {
    const location = useLocation();
    const localeFromUrl = LOCALE_REGEX.exec(location.pathname)?.[0] || LOCALES.EN;

    return (
        <>
            {headerImage?.[0] && <PageTitle image={headerImage[0].image}>{pageTitle}</PageTitle>}
            <div className={classes.container_wrapper}>
                <div className={classes.container}>
                    <div className={classNames(classes.banner_wrapper)}>
                        <img className={classNames(classes.banner)} src={getImageUrl(image.url)} alt={image.name} />
                        <div className={classNames(classes.imageHover)}>
                            <div className={classNames(classes.text_block)}>
                                <div className={classNames(classes.labelContainer)}>
                                    <div className={classNames(classes.labelContainer__label)}>
                                        {translate(localeFromUrl, label as TranslationsEnum)}
                                    </div>
                                    <div className={classNames(classes.labelContainer__date)}>
                                        {new Date(date).toLocaleDateString(locale)}
                                    </div>
                                </div>
                                <div className={classNames(classes.heading)}>{title}</div>
                            </div>
                        </div>
                    </div>
                    {caption && <div className={classNames(classes.caption)}>{caption}</div>}
                    {sections.map(({ heading, richText }) => (
                        <div key={heading} className={classes.text_wrapper}>
                            <h1 className={classNames(classes.subHeading)}>{heading}</h1>
                            <HtmlMarkdownContent content={richText.content} withSpacing={false} />
                        </div>
                    ))}
                    {authorCard && (
                        <EmployeeCardSection
                            title={authorCard.title}
                            id={authorCard.id}
                            icon={authorCard.icon}
                            label={translate(localeFromUrl, authorCard.label as TranslationsEnum)}
                            name={authorCard.name}
                            email={authorCard.email}
                            url={authorCard.url}
                            newTab={authorCard.newTab}
                        />
                    )}
                    <If condition={!!banner}>
                        <div className={classes.bannerBottom}>
                            <Banner data={banner} />
                        </div>
                    </If>
                </div>
                <div className={classes.latest_news_block}>
                    <Link className={classes.latest_news_heading} url={newsLink}>
                        {latestNewsHeader || ''}
                    </Link>
                    {sortPostsByDate(latestPosts.edges)
                        .slice(0, MAX_POSTS_TO_SHOW)
                        .map(({ node }) => (
                            <PostCard
                                key={node.id}
                                id={node.id}
                                label={translate(localeFromUrl, node.type as TranslationsEnum)}
                                date={new Date(node.date ?? node.published_at).toLocaleDateString(node.locale)}
                                title={node.title}
                                paragraph={node.section?.[0]?.richText.content}
                                readMore={translate(localeFromUrl, TranslationsEnum.read_more)}
                                to={`/${node.locale}/${getPageSlugByType(PageType.news, pageTypes)}/${node.slug}`}
                                shouldResize
                            />
                        ))}
                </div>
            </div>
        </>
    );
};
