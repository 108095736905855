import { LOCALES } from 'src/constants/locales';
import { RU, EN, LT } from 'src/constants/translations';
import { TranslationsEnum } from 'src/enums/TranslationsEnum';

export const translate = (locale: string, text: TranslationsEnum): string => {
    if (locale === LOCALES.EN && EN[text]) {
        return EN[text];
    }
    if (locale === LOCALES.LT && LT[text]) {
        return LT[text];
    }
    if (locale === LOCALES.RU && RU[text]) {
        return RU[text];
    }
    return text;
};
