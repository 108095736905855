import React, { FC } from 'react';
import { IPostCard } from 'interfaces';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import classes from './PostCard.module.scss';

export const PostCard: FC<IPostCard> = ({
    label,
    date,
    title,
    paragraph,
    readMore,
    to,
    shouldResize = false,
    className,
    classNameTitle,
}) => (
    <Link
        to={to}
        className={classNames(classes.container, className, {
            [classes.shouldResize]: shouldResize,
        })}
    >
        <div className={classes.label_wrapper}>
            <span className={classes.label}>{label}</span>
            <span className={classes.date}>{date}</span>
        </div>
        <h3 className={classNames(classNameTitle || classes.title)}>{title}</h3>
        <div className={classNames(classes.paragraph)}>
            <HtmlMarkdownContent content={paragraph} withSpacing={false} />
        </div>
        <span className={classNames(classes.read_more)}>{readMore}</span>
    </Link>
);
