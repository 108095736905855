import React from 'react';
import classNames from 'classnames';
import { IBulletList } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';
import { ButtonList } from 'components/ButtonList';
import classes from './BulletList.module.scss';

interface IProps {
    data: IBulletList;
    className?: string;
    shouldAlignLeft: boolean;
}

export const BulletList: React.FC<IProps> = (props) => {
    const { data } = props;

    const style = data.backgroundImage
        ? {
              backgroundImage: `url("${getImageUrl(data.backgroundImage.url)}")`,
          }
        : undefined;

    return (
        <div
            className={classNames(classes.container, props.className, { [classes.alignLeft]: props.shouldAlignLeft })}
            style={style}
        >
            <div className={classNames(classes.listContainer, { [classes.alignLeft]: props.shouldAlignLeft })}>
                <If condition={!!data.title}>
                    <h3 className={classes.title}>{data.title}</h3>
                </If>
                <ul className={classes.list}>
                    {data.list.map((row) => (
                        <li key={row.id}>{row.text}</li>
                    ))}
                </ul>
                <If condition={!!data.buttons?.length}>
                    <ButtonList buttons={data.buttons} className={classes.button} />
                </If>
            </div>
        </div>
    );
};
