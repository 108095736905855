import React, { useState, useRef, FC, Children } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import arrowIcon from 'images/arrow.svg';

import classes from './index.module.scss';
import './customCarousel.scss';

export const Carousel: FC = ({ children }) => {
    const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
    const childrenLength = Children.count(children);
    const carouselRef = useRef<Slider>(null);

    const nextSlide = (): void => {
        carouselRef.current?.slickNext();
        setCurrentSlideIdx((prev) => prev + 1);
    };

    const prevSlide = (): void => {
        carouselRef.current?.slickPrev();
        setCurrentSlideIdx((prev) => prev - 1);
    };

    return (
        <div>
            <Slider
                className="default-carousel"
                ref={carouselRef}
                arrows={false}
                dots={false}
                speed={500}
                draggable={false}
                swipe={false}
                autoplay
                autoplaySpeed={5000}
                afterChange={setCurrentSlideIdx}
            >
                {children}
            </Slider>

            <div className={classes.Carousel}>
                <button type="button" onClick={prevSlide} className={classes.Carousel__arrow__left}>
                    <img src={arrowIcon} alt="previous" />
                </button>
                {[...Array(childrenLength)].map((_, idx) => (
                    <div
                        key={idx}
                        className={classNames(classes.Carousel__dot, {
                            [classes.Carousel__dot__active]: idx === currentSlideIdx,
                        })}
                    />
                ))}
                <button type="button" onClick={nextSlide} className={classes.Carousel__arrow__right}>
                    <img src={arrowIcon} alt="next" />
                </button>
            </div>
        </div>
    );
};
