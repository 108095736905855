import React from 'react';

import { getImageUrl } from 'utils/getImageUrl';
import { IImage } from 'interfaces';

import classes from './PageTitle.module.scss';

export const PageTitle: React.FC<{ image?: IImage }> = ({ image, children }) => (
    <div className={classes.container} style={{ background: image && `url(${getImageUrl(image.url)})` }}>
        <h1>{children}</h1>
    </div>
);
