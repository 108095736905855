import React from 'react';
import classNames from 'classnames';
import { get, debounce } from 'lodash-es';
import classes from './MarqueeAnimation.module.scss';

interface IProps<T extends unknown> {
    list: T[];
    itemKey: string;
    itemTitle?: string;
    className?: string;
    renderItem(item: T): JSX.Element;
}

const ITEM_MARQUEE_DURATION = 7; // s

export const MarqueeAnimation = <T,>(props: IProps<T>): JSX.Element => {
    const listRef = React.useRef<HTMLDivElement>(null);
    const [animate, setAnimate] = React.useState<boolean>(false);

    const renderList = React.useCallback(
        () =>
            props.list.map((item) => (
                <div
                    key={get(item, props.itemKey)}
                    title={props.itemTitle ? get(item, props.itemTitle) : undefined}
                    className={classes.item}
                >
                    {props.renderItem(item)}
                </div>
            )),
        [props.list],
    );

    React.useEffect(() => {
        const debounceUpdateAnimation = debounce(() => {
            if (!listRef.current) {
                return;
            }

            const realWidth =
                listRef.current.offsetWidth / (props.list.length === listRef.current.childElementCount ? 1 : 2);

            setAnimate(realWidth > window.outerWidth);
        }, 300);

        window.addEventListener('resize', debounceUpdateAnimation);
        debounceUpdateAnimation();

        return () => {
            debounceUpdateAnimation.cancel();
            window.removeEventListener('resize', debounceUpdateAnimation);
        };
    }, [props.list]);

    return (
        <div className={classNames(classes.container, props.className)}>
            <div
                ref={listRef}
                className={classNames(classes.list, { [classes.animate]: animate })}
                style={{ animationDuration: `${props.list.length * ITEM_MARQUEE_DURATION}s` }}
            >
                {renderList()}
                <If condition={animate}>{renderList()}</If>
            </div>
        </div>
    );
};
