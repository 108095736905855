import React, { FC, useState } from 'react';

import { Button } from 'components/Button';
import { Popup } from 'components/Popup';
import { ButtonType } from 'enums/ButtonType';
import { isDomAvailable } from 'utils/isDomAvailable';

import { IAgeCheck } from './interface';
import classes from './AgeCheck.module.scss';

export const AgeCheck: FC<IAgeCheck> = ({ data }) => {
    const { primaryText, secondaryText, acceptButtonText, declineButtonText } = data;

    const [hidden, setHidden] = useState(false);
    const onAccept = (): void => {
        window.localStorage.setItem('is-old-enough', 'true');
        setHidden(true);
    };

    if (!isDomAvailable() || !!window.localStorage.getItem('is-old-enough') || hidden) {
        return null;
    }

    return (
        <Popup className={classes.container}>
            <div className={classes.warningIcon}>!</div>
            <h2 className={classes.primaryText}>{primaryText}</h2>
            <div className={classes.secondaryText}>{secondaryText}</div>
            <div className={classes.buttons}>
                <Button type={ButtonType.DEFAULT_DARK} onClick={onAccept}>
                    {acceptButtonText}
                </Button>
                <Button url="not-old-enough" type={ButtonType.DEFAULT_DARK}>
                    {declineButtonText}
                </Button>
            </div>
        </Popup>
    );
};
