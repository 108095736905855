import React, { FC } from 'react';

import { IWhereToPlay } from 'interfaces';
import { Card } from './Card';

import classes from './WhereToPlaySection.module.scss';

export const WhereToPlaySection: FC<IWhereToPlay> = ({ cards, header }) => (
    <div className={classes.section}>
        <h2 className={classes.section__header}>{header}</h2>
        <div className={classes.section__content}>
            {cards.map(({ buttonText, description, id, image, link, newTab }) => (
                <Card
                    key={id}
                    buttonText={buttonText}
                    description={description}
                    id={id}
                    image={image}
                    link={link}
                    newTab={newTab}
                />
            ))}
        </div>
    </div>
);
