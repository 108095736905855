import React from 'react';
import classNames from 'classnames';

import { IHero } from 'src/interfaces';
import { ButtonList } from 'components/ButtonList';
import { Image } from 'components/Image';
import { Carousel } from 'components/Carousel';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './Hero.module.scss';

interface IProps {
    slides: IHero[];
}

export const Hero: React.FC<IProps> = (props) => (
    <div className={classes.Hero}>
        <Carousel>
            {props.slides.map(({ title, description, buttons, id, image }) => {
                const hasContent = !!(title || description || buttons.length);

                return (
                    <div key={id} className={classes.Hero__container}>
                        <div
                            key={id}
                            className={classNames(classes.Hero__container__slide, {
                                [classes.Hero__container__slide__centered]: !hasContent,
                            })}
                        >
                            {hasContent && (
                                <div className={classes.Hero__container__slide__content}>
                                    <div>
                                        <h1 className={classes.Hero__container__title}>{title}</h1>
                                        <p className={classes.Hero__container__description}>{description}</p>
                                        <div className={classes.Hero__container__slide__content__buttons}>
                                            <ButtonList
                                                buttons={buttons}
                                                className={classes.Hero__container__slide__content__buttons__button}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {image && <Image image={image} className={classes.Hero__container__slide__image} />}
                        </div>
                    </div>
                );
            })}
        </Carousel>
    </div>
);
