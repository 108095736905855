import React, { FC } from 'react';

import { IText } from 'src/interfaces';
import { Image } from 'components/Image';
import classes from './index.module.scss';

export const Text: FC<IText> = ({ icon, title, color }) => (
    <div className={classes.Text}>
        {icon && <Image image={icon} className={classes.Text__icon} />}
        <p style={{ color: color?.hex }}>{title}</p>
    </div>
);
