import React, { FC } from 'react';
import classNames from 'classnames';

import { getImageUrl } from 'utils/getImageUrl';
import { IFormBase } from 'interfaces';

import classes from './index.module.scss';

export const FormBase: FC<IFormBase> = ({
    children,
    header,
    className,
    description,
    centerText = false,
    backgroundImage,
}) => (
    <div className={classNames(className, classes.FormBase)}>
        <div
            className={classes.FormBase__background}
            style={{
                background: backgroundImage ? `url(${getImageUrl(backgroundImage.url)})` : undefined,
            }}
        >
            <div
                className={classNames(classes.FormBase__text, {
                    [classes.FormBase__text__center]: centerText,
                })}
            >
                <h2>{header}</h2>
                <p>{description}</p>
            </div>
            {children}
        </div>
    </div>
);
