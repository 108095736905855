import { useState, useEffect, MutableRefObject } from 'react';

export const useDetectIfInViewPort = (ref: MutableRefObject<HTMLDivElement>): boolean => {
    const [isIntersecting, setIntersecting] = useState(false);

    let observer: IntersectionObserver | null = null;

    useEffect(() => {
        observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    }, []);

    useEffect(() => {
        observer?.observe(ref.current);
        return () => {
            observer?.disconnect();
        };
    }, [observer]);

    return isIntersecting;
};
