import React, { FC } from 'react';

import { ICardWithColumns, IColumnList } from 'interfaces';
import classNames from 'classnames';
import { getImageUrl } from 'utils/getImageUrl';
import { CardColumns } from 'enums/CardColumns';

import { getTextSizeClass } from 'src/utils/getTextSizeClass';
import defaultClasses from './Card.module.scss';
import customClasses from './CardWithColumns.module.scss';

interface IColumns {
    sizeClass: string;
    columnList: IColumnList[];
}

const Column: FC<IColumns> = ({ columnList, sizeClass }) => (
    <>
        {columnList.map(({ text, blueText, column, id }) => (
            <div
                key={id + column}
                className={classNames(customClasses.columns__second__text, {
                    [customClasses[sizeClass as string]]: sizeClass,
                })}
            >
                <span className={customClasses.columns__second__text__blue}>{blueText}</span>
                <span>{text}</span>
            </div>
        ))}
    </>
);
export const CardWithColumns: FC<ICardWithColumns> = ({ icon, heading, label, columnList, textSize }) => {
    const sizeClass = getTextSizeClass(textSize);

    return (
        <div className={classNames(defaultClasses.container, customClasses.container)}>
            <div className={defaultClasses.icon_wrapper}>
                <img className={defaultClasses.icon} src={getImageUrl(icon.url)} alt={icon.name} />
            </div>
            <p className={defaultClasses.label}>{label}</p>
            <h3 className={classNames(defaultClasses.heading, customClasses.heading)}>{heading}</h3>
            <div className={customClasses.columns}>
                <div className={customClasses.columns__first}>
                    <Column
                        columnList={columnList.filter(({ column }) => column === CardColumns.FIRST)}
                        sizeClass={sizeClass}
                    />
                </div>
                <div className={customClasses.columns__second}>
                    <Column
                        columnList={columnList.filter(({ column }) => column === CardColumns.SECOND)}
                        sizeClass={sizeClass}
                    />
                </div>
            </div>
        </div>
    );
};
