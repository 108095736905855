import React, { useState, FC } from 'react';
import classNames from 'classnames';

import { IBlock } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';
import { ButtonList } from 'components/ButtonList';
import { Image } from 'components/Image';
import { Heading } from 'components/Heading';
import { MarqueeAnimation } from 'components/MarqueeAnimation';
import { BlockCarousel } from 'components/Carousel';

import classes from './Block.module.scss';

export const Block: FC<IBlock> = ({
    backgroundImage,
    imagesList,
    sectionHeading,
    reversContentOrder,
    heading,
    subheading,
    buttons,
    clickableCarousel,
    image,
    fixedHeightImage,
    paragraphWithColor,
}) => {
    const styles = backgroundImage
        ? {
              backgroundImage: `url("${getImageUrl(backgroundImage.url)}")`,
          }
        : undefined;
    const firstItemId = clickableCarousel && imagesList.length ? imagesList[0].id : undefined;
    const [activeImageId, setActiveImageId] = useState<number | undefined>(firstItemId);
    const activeImage = imagesList?.find(({ id }) => id === activeImageId);
    const numberOfItems = imagesList?.length;
    const clickedImage = (currentSlide: number): void => {
        setActiveImageId(imagesList[currentSlide].id);
    };
    return (
        <>
            <div style={styles} className={classes.container}>
                {sectionHeading && (
                    <h2 className={classes.sectionHeading} dangerouslySetInnerHTML={{ __html: sectionHeading }} />
                )}
                <div className={classNames(classes.content, { [classes.contentReverse]: reversContentOrder })}>
                    <div className={classNames(classes.heading, { [classes.headingReverse]: reversContentOrder })}>
                        <div className={classes.headingContent}>
                            {!!heading && (
                                <Heading
                                    paragraphWithColor={paragraphWithColor}
                                    heading={heading}
                                    subheadings={subheading}
                                />
                            )}
                        </div>
                        {!!buttons?.length && (
                            <div>
                                <ButtonList buttons={buttons} />
                            </div>
                        )}
                    </div>
                    {(!!image || activeImage) && (
                        <div
                            className={classNames(classes.imageWrapper, {
                                [classes.paddingReverse]: reversContentOrder,
                            })}
                        >
                            <Image
                                image={clickableCarousel && activeImage ? activeImage : image}
                                className={classNames(classes.image, { [classes.fixedHeightImage]: fixedHeightImage })}
                            />
                        </div>
                    )}
                </div>
                <If condition={!!imagesList?.length && !clickableCarousel}>
                    <MarqueeAnimation
                        list={imagesList}
                        className={classes.list}
                        itemKey="id"
                        renderItem={({ url }) => (
                            <div
                                className={classes.listImage}
                                style={{ backgroundImage: `url("${getImageUrl(url)}")` }}
                            />
                        )}
                    />
                </If>
                {!!numberOfItems && clickableCarousel && (
                    <BlockCarousel numberOfItems={numberOfItems} clickedImage={clickedImage}>
                        {imagesList.map(({ url, id }) => (
                            <button
                                key={id}
                                className={classes.carouselItem}
                                onClick={() => setActiveImageId(id)}
                                type="button"
                            >
                                <img
                                    className={classNames({
                                        [classes.imageSelected]: id === activeImageId,
                                    })}
                                    src={getImageUrl(url)}
                                    alt="product"
                                />
                            </button>
                        ))}
                    </BlockCarousel>
                )}
            </div>
        </>
    );
};
