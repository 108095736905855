import React, { FC } from 'react';

import { InputType } from 'enums/InputType';
import { IInputBase } from 'interfaces';
import { Input } from './Input';
import { TextArea } from './TextArea';
import { FileInput } from './FileInput';

import classes from './index.module.scss';

export const Base: FC<IInputBase> = ({ ...rest }) => {
    const { error, title, type, className, placeholder, register, name, resetField } = rest;

    return (
        <div className={classes.Input}>
            <p className={classes.Input__label}>{title}</p>
            <div className={classes.Input__container}>
                {InputType.TEXTAREA === type && (
                    <TextArea
                        error={error}
                        className={className}
                        register={register}
                        placeholder={placeholder}
                        name={name}
                    />
                )}
                {[InputType.INPUT, InputType.EMAIL, InputType.TEXT].includes(type as InputType) && (
                    <Input
                        error={error}
                        className={className}
                        register={register}
                        placeholder={placeholder}
                        name={name}
                        type={type}
                    />
                )}
                {InputType.FILE === type && (
                    <FileInput
                        error={error}
                        className={className}
                        register={register}
                        placeholder={placeholder}
                        name={name}
                        resetField={resetField}
                    />
                )}
            </div>
            {error && <div className={classes.Input__error}>{error}</div>}
        </div>
    );
};
