import React, { useState, useRef, useEffect, FC, MutableRefObject } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import { IVideo } from 'interfaces';
import playIcon from 'images/playIcon.png';
import classes from './Video.module.scss';
import { useDetectIfInViewPort } from './hooks/useDetectIfInViewPort';

export const Video: FC<IVideo> = ({ autoplayWhenVisible, link, className, light, title, muted, isPlayIcon }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    const isVisible = useDetectIfInViewPort(ref);

    useEffect(() => {
        if (autoplayWhenVisible) {
            setIsPlaying(isVisible);
        }
    }, [isVisible, autoplayWhenVisible, setIsPlaying]);

    return (
        <div className={classes.Video}>
            {title && <p className={classes.Video__title}>{title}</p>}
            <div ref={ref} className={classNames(className, classes.Video__container)}>
                <ReactPlayer
                    width="100%"
                    height="100%"
                    controls
                    muted={muted}
                    loop
                    playing={isPlaying}
                    url={link}
                    light={light ? light || !isPlaying : isPlayIcon}
                    playIcon={
                        isPlayIcon ? (
                            light ? (
                                <div className={classes.playIcon}>
                                    <img src={playIcon} alt="play icon" />
                                </div>
                            ) : (
                                <div className={classes.test}>
                                    <img src={playIcon} alt="play icon" />
                                </div>
                            )
                        ) : (
                            <></>
                        )
                    }
                />
            </div>
        </div>
    );
};
