import React, { useMemo } from 'react';
import classNames from 'classnames';
import { IGatsbyConnection, IGatsbyEdge } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { Banner } from 'components/Banner';
import { GamesBlock } from 'components/Games/GamesBlock';
import { IBanner, IGame, IGameComponent, IPageTypes } from 'interfaces';
import { Heading } from 'components/Heading';
import { Filters } from 'components/Filters';
import { Image } from 'components/Image';
import { getPageSlugByType } from 'src/utils/getPageSlugByType';
import { PageType } from 'src/enums/PageType';
import classes from './Games.module.scss';

interface IProps extends IGameComponent {
    games: IGatsbyConnection<IGame>;
    banner: IBanner;
    pageTypes: IPageTypes;
}

export const Games: React.FC<IProps> = (props) => {
    const getUniqueCategories = (games: IGatsbyEdge<IGame>[]): string[] => {
        const uniqueCategories = games
            .map(({ node }) => node.gameType?.type)
            .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);

        uniqueCategories.unshift(props.typeAll);
        return uniqueCategories;
    };

    const getFilterValues = (uniqueCategories: string[]): Record<string, string> =>
        uniqueCategories.reduce((filterObject: Record<string, string>, value: string) => {
            if (value) {
                filterObject[value] = value;
            }
            return filterObject;
        }, {});

    const uniqueCategories = useMemo(() => getUniqueCategories(props.games.edges), [props.games.edges]);
    const filterValuesObject = useMemo(() => getFilterValues(uniqueCategories), [uniqueCategories]);

    const [filterValue, setFilterValue] = React.useState<string>(props.typeAll);

    return (
        <div className={classes.container}>
            <div
                className={classNames({
                    [classes.container__grid]: props.headingImage,
                })}
            >
                <Heading heading={props.heading} className={classes.heading} />
                {props.headingImage && <Image image={props.headingImage} />}
            </div>
            <Filters filters={filterValuesObject} onClick={setFilterValue} activeValue={filterValue} />
            <div className={classes.gamesContainer}>
                {props.games.edges
                    .filter((edge) => filterValue === props.typeAll || edge.node.gameType?.type === filterValue)
                    .sort((a, b) => a.node.order - b.node.order)
                    .map(({ node }) => (
                        <GamesBlock
                            key={node.id}
                            image={node.image}
                            name={node.name}
                            url={`/${node.locale}/${getPageSlugByType(PageType.products, props.pageTypes)}/${
                                node.slug
                            }`}
                        />
                    ))}
            </div>
            <If condition={!!props.banner}>
                <Banner data={props.banner} />
            </If>
        </div>
    );
};
