import React from 'react';
import classNames from 'classnames';
import { InputType } from 'enums/InputType';
import classes from './Input.module.scss';

interface IProps {
    value?: string;
    name: string;
    className?: string;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    isError?: boolean;
    placeholder?: string;
    isRequired?: boolean;
    type: InputType;
}

export const Input = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
    const Component = props.type === InputType.TEXTAREA ? 'textarea' : 'input';

    return React.createElement(
        Component,
        {
            ref,
            type: props.type,
            value: props.value,
            className: classNames(classes.container, classes[props.type], props.className, {
                [classes.invalid]: props.isError,
            }),
            onChange: props.onChange,
            onFocus: props.onFocus,
            onBlur: props.onBlur,
            name: props.name,
            required: props.isRequired,
            placeholder: props.placeholder,
        },
        null,
    );
});
