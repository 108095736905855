/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import { IPositionInner } from 'interfaces';
import { PageTitle } from 'components/PageTitle';
import { PositionsIntro } from 'components/PositionsIntro';
import { ApplicationForm } from 'components/forms';

interface IPositionInnerProps {
    data: IPositionInner;
}

export const PositionsInner: FC<IPositionInnerProps> = ({ data }) => {
    const { positionsIntro, title, applicationForm, headerImage, locale } = data;

    return (
        <div>
            <PageTitle image={headerImage[0]?.image}>{title}</PageTitle>
            {positionsIntro.length && <PositionsIntro {...positionsIntro[0]} />}
            {applicationForm.length && <ApplicationForm {...applicationForm[0]} position={title} locale={locale} />}
        </div>
    );
};
