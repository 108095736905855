import React, { FC, MouseEventHandler } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import arrowIcon from 'images/arrowGray.svg';

import { BREAKPOINTS } from 'constants/breakpoints';

import './customBlockCarousel.scss';
import classes from './Block.module.scss';
import 'slick-carousel/slick/slick.css';

interface IBlockCarousel {
    numberOfItems: number;
    clickedImage: any;
}
interface IProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
}
export const BlockCarousel: FC<IBlockCarousel> = ({ children, numberOfItems, clickedImage }) => {
    const NextArrow: FC<IProps> = ({ onClick }) => (
        <button className={classNames(classes.carouselButton, classes.nextButton)} type="button" onClick={onClick}>
            <img src={arrowIcon} alt="icon next" />
        </button>
    );
    const PrevArrow: FC<IProps> = ({ onClick }) => (
        <button className={classNames(classes.carouselButton, classes.prevButton)} type="button" onClick={onClick}>
            <img src={arrowIcon} alt="icon previous" />
        </button>
    );
    const CAROUSEL_RESPONSIVE_VALUES = [
        {
            breakpoint: 3000,
            settings: {
                slidesToShow: Math.min(numberOfItems, 5),
                variableWidth: false,
                centerMode: true,
            },
        },
        {
            breakpoint: BREAKPOINTS.XX_LARGE,
            settings: {
                slidesToShow: Math.min(numberOfItems, 4),
                variableWidth: false,
                centerMode: true,
            },
        },
        {
            breakpoint: BREAKPOINTS.X_LARGE,
            settings: {
                slidesToShow: Math.min(numberOfItems, 3),
                variableWidth: false,
                centerMode: true,
            },
        },
        {
            breakpoint: BREAKPOINTS.MEDIUM,
            settings: {
                slidesToShow: Math.min(numberOfItems, 2),
                variableWidth: numberOfItems === 1,
                centerMode: true,
            },
        },
        {
            breakpoint: BREAKPOINTS.SMALL,
            settings: {
                slidesToShow: 1,
                variableWidth: false,
                centerMode: true,
            },
        },
    ];

    return (
        <Slider
            className={classNames(classes.slider, 'block-carousel')}
            infinite
            slidesToScroll={1}
            autoplay
            autoplaySpeed={3000}
            swipeToSlide
            afterChange={clickedImage}
            responsive={CAROUSEL_RESPONSIVE_VALUES}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
        >
            {children}
        </Slider>
    );
};
