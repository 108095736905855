import React, { FC } from 'react';

import { IVideoSection } from 'interfaces';
import { getImageUrl } from 'utils/getImageUrl';
import { HtmlMarkdownContent } from 'components/HtmlMarkdownContent';
import classes from './VideoSection.module.scss';
import { Video } from '../Video';

export const VideoSection: FC<IVideoSection> = ({ background, heading, video, subheading, text }) => (
    <>
        <div
            className={classes.wrapper}
            style={{
                backgroundImage: `url(${getImageUrl(background.url)})`,
            }}
        >
            {heading && <h2 className={classes.heading}>{heading}</h2>}
            {subheading && <h4 className={classes.subheading}>{subheading}</h4>}
            {!!text && (
                <p className={classes.text}>
                    <HtmlMarkdownContent content={text} withSpacing={false} />
                </p>
            )}

            <div className={classes.container}>
                <Video link={video.link} autoplayWhenVisible className={classes.player} muted />
            </div>
        </div>
    </>
);
