import React, { useState, FC, useMemo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { navigate } from 'gatsby';
import { http } from 'utils/http';
import { Loader } from 'components/Loader';
import { Message } from 'components/Contacts/Message';
import { useMessage } from 'components/Contacts/hooks/useMessage';
import { IMessage } from 'components/Contacts/interfaces';
import { Input } from 'src/components/inputs';
import { ITwainSportsForm } from 'interfaces';
import { FormBase } from './Base';

import classes from './index.module.scss';
import { LayoutContext } from 'src/templates/Layout/Layout';

const FIELD_NAMES = {
    email: 'email',
    companyName: 'companyName',
    message: 'message',
};

export const TwainSportForm: FC<ITwainSportsForm> = ({
    companyName,
    email,
    message,
    submitText,
    header,
    messageError,
    emailTo,
    locale,
}) => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            [FIELD_NAMES.email]: '',
            [FIELD_NAMES.companyName]: '',
            [FIELD_NAMES.message]: '',
        },
        resolver: yupResolver(
            yup.object({
                [FIELD_NAMES.email]: yup
                    .string()
                    .email(email.errorIsNotValid || 'Email is not valid')
                    .required(email.errorIsRequired),
                [FIELD_NAMES.companyName]: companyName.errorIsRequired
                    ? yup.string().required(companyName.errorIsRequired)
                    : yup.string(),
                [FIELD_NAMES.message]: yup.string().required(message.errorIsRequired || 'Message is required'),
            }),
        ),
    });
    const [isFetching, setIsFetching] = useState(false);
    const context = useContext(LayoutContext);
    const { message: responseMessage, updateMessage, resetMessage } = useMessage();
    const isError = !!Object.keys(errors).length;
    const watchAllFields = watch();
    const formRequiredValues = {
        ...(!!companyName.errorIsRequired && { [FIELD_NAMES.companyName]: getValues('companyName') }),
        [FIELD_NAMES.email]: getValues('email'),
        [FIELD_NAMES.message]: getValues('message'),
    };

    const onSubmit = ({
        email: _email,
        companyName: _companyName,
        message: _message,
    }: {
        [x: string]: string;
    }): void => {
        const formValue = {
            emailFrom: _email,
            companyName: _companyName,
            message: _message,
            emailTo: emailTo.id,
        };
        resetMessage();
        setIsFetching(true);

        http.post<IMessage>('/twain-sport-emails', {
            data: formValue,
        })
            .then((response) => {
                if (response.error) {
                    updateMessage({ error: messageError });
                } else {
                    navigate(`/${locale}/${context.thankYouPageSlug}`, { replace: true });
                }
            })
            .catch((e) => {
                updateMessage({ error: e.toString() });
            })
            .finally(() => {
                setIsFetching(false);
            });
    };
    const formInvalid = useMemo(
        (): boolean =>
            Object.values(formRequiredValues)
                .map((value) => !!value)
                .includes(false),
        [watchAllFields],
    );

    return (
        <FormBase header={header} className={classes.TwainSportsForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    register={register}
                    type={email.type}
                    placeholder={email.placeholder}
                    title={email.title}
                    name={FIELD_NAMES.email}
                    error={errors[FIELD_NAMES.email]?.message}
                />
                <Input
                    register={register}
                    type={companyName.type}
                    placeholder={companyName.placeholder}
                    title={companyName.title}
                    name={FIELD_NAMES.companyName}
                    error={errors[FIELD_NAMES.companyName]?.message}
                />
                <Input
                    register={register}
                    type={message.type}
                    placeholder={message.placeholder}
                    title={message.title}
                    name={FIELD_NAMES.message}
                    error={errors[FIELD_NAMES.message]?.message}
                />
                {isFetching && <Loader className={classes.spinner} />}
                {!!responseMessage.error && <Message message={responseMessage} />}
                <button disabled={isError || formInvalid || isFetching} type="submit">
                    {submitText}
                </button>
            </form>
        </FormBase>
    );
};
