import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { IBenefits } from 'interfaces';
import { BulletList } from 'components/BulletList';
import { ButtonList } from 'components/ButtonList';
import { HeadingSize } from 'enums/HeadingSize';

import classes from './Benefits.module.scss';

const resolveHeadingByTitleSize = (titleSize: string, title: string): ReactNode | null => {
    if (titleSize === HeadingSize.H2) {
        return <h2 className={classes.title}>{title}</h2>;
    }
    if (titleSize === HeadingSize.H3) {
        return <h3 className={classes.title}>{title}</h3>;
    }
    if (titleSize === HeadingSize.H4) {
        return <h4 className={classes.title}>{title}</h4>;
    }
    return null;
};

export const Benefits: React.FC<IBenefits> = ({ bulletLists, title, shouldAlignLeft, buttons, titleSize }) => {
    const numberOfCards = bulletLists?.length;

    return (
        <div className={classes.container}>
            {title && resolveHeadingByTitleSize(titleSize, title)}
            <div className={classes.listContainer}>
                {bulletLists?.map((list) => (
                    <BulletList
                        key={list.id}
                        data={list}
                        shouldAlignLeft={shouldAlignLeft}
                        className={classNames(classes.list, {
                            [classes.oneCardMargin]: shouldAlignLeft && numberOfCards === 1,
                            [classes.twoCardMargin]: shouldAlignLeft && numberOfCards === 2,
                        })}
                    />
                ))}
            </div>
            <If condition={!!buttons?.length}>
                <ButtonList buttons={buttons} className={classes.button} />
            </If>
        </div>
    );
};
