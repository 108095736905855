export enum BannerBackgroundColor {
    WHITE = 'white',
    SEASHELL = 'seashell',
    SERENADE = 'serenade',
    ICEBERG = 'iceberg',
    SPACE = 'space',
    TURQUOISE = 'turquoise',
    BLUE_ZODIAC = 'blueZodiac',
    GALAXY = 'galaxy',
    NEBULA = 'nebula',
}
