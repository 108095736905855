export const PostTypes = {
    ALL: 'all',
    BUSINESS: 'business',
    EVENTS: 'events',
    PARTNERS: 'partners',
    PRODUCT: 'product',
};

export const EN = {
    read_more: 'Read more',
    [PostTypes.BUSINESS]: 'business',
    [PostTypes.EVENTS]: 'events',
    [PostTypes.PARTNERS]: 'partners',
    [PostTypes.PRODUCT]: 'product',
};
export const RU = {
    read_more: 'Подробнее',
    [PostTypes.BUSINESS]: 'Интеграции',
    [PostTypes.EVENTS]: 'Игры',
    [PostTypes.PARTNERS]: 'Аудитория',
    [PostTypes.PRODUCT]: 'Рынки',
};
export const LT = {
    read_more: 'Skaityti daugiau',
    [PostTypes.BUSINESS]: 'Verslas',
    [PostTypes.EVENTS]: 'Įvykiai',
    [PostTypes.PARTNERS]: 'Partneriai',
    [PostTypes.PRODUCT]: 'Produktai',
};
